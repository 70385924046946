import React from "react";
import styles from './style.module.css';
import Header from '../components/Header/headerForm.jsx';
import Footer from '../components/Footer/FooterForm.jsx';
import Carousel from 'react-elastic-carousel';
import { useRef,useState , useEffect } from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';
import { Checkmark } from 'react-checkmark'
import { useNavigate } from 'react-router-dom';

import axios from 'axios';
import style from "./Vagas.module.css";

import ReactGA from "react-ga4";

ReactGA.initialize("AW-16651989164");

const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2 },
    { width: 940, itemsToShow: 3 },
    { width: 1200, itemsToShow: 4 },
  ];


  
const PrincipalIndex = () => {
    const navigate = useNavigate();
    const [modal_C, setModal_C] = useState(false);
    const toggle_C = () => setModal_C(!modal_C);
    const [estagioList, setEstagioList] = useState([])
    const carouselRef = useRef(null);
    const intervaloTrocaPagina = 5000;

    

    const shuffleArray = (array) => {
        const shuffledArray = [...array];
        for (let i = shuffledArray.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
        }
        return shuffledArray;
    };


    useEffect(() => {
        axios.get('https://linkestagio.com.br/App.php?operation=get-front-vagas')
          .then(response => {
            if (Array.isArray(response.data)) {
                setEstagioList(shuffleArray(response.data));
            }
          })
          .catch(error => {
            console.error('Erro:', error);
          });

    }, []);


    useEffect(() => {



      setTimeout(() => {


        const urlParams = new URLSearchParams(window.location.search);
        const entrevista = urlParams.get('Entrevista');
        console.log(entrevista);

        if (entrevista == 1) {
          toggle_C();
          urlParams.delete('Entrevista');
          navigate({ search: urlParams.toString() }, { replace: true });
        }

      }, 1500);

      
    }, []);

    const handleSlideChange = (currentSlide, totalSlides) => {
        if (currentSlide === totalSlides - 1) {
          setTimeout(() => {
            carouselRef.current.goToSlide(0);
          }, intervaloTrocaPagina);
        }
      };


    return (
        <>
            <Header />
            <div className={styles.largeDiv}></div> {/* Adicione a classe aqui */}

            <div className={styles.fullBackground}></div>
            <div className={`${style.Back_Cards}`}>
            
            {estagioList.length >= 6 && (
            <>
            <div className={`${style.Title}`}>
                Veja algumas de nossas vagas
            </div>
            <Carousel
              ref={carouselRef}
              isRTL={false}
              autoPlaySpeed={intervaloTrocaPagina}
              enableAutoPlay={true}
              pagination={false}
              showArrows={false}
              breakPoints={breakPoints}
              infinite
              customTransition="all .5"
              afterChange={handleSlideChange}
            >
                    {estagioList.map((estagio) => (
                    <div key={estagio.id} >
                        <div className={`${style.cardBody}`}>
                        <p className={`${style.cardTitle}`}>Vaga - N° {500 + parseInt(estagio.idVaga, 10)}</p>
                        <p className={`${style.cardInfo}`}>
                          Bolsa auxílio: R${
                          estagio.Remuneracao 
                          ? parseFloat(estagio.Remuneracao).toLocaleString('pt-BR', {
                              style: 'decimal',
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2
                            })
                          : '0,00'
                        }                
                        </p>
                        <p className={`${style.cardInfo}`}>Período: {estagio.Periodo_Estagio}</p>
                        <p className={`${style.cardInfo}`}>Cidade: {estagio.Cidade}</p>
                        <p className={`${style.cardInfo}`}>Bairro: {estagio.Bairro}</p>
                        <p className={`${style.cardInfo}`}>Cursos Desejados:</p>
                        {estagio.Curso_Desejado_1 && (
                        <p className={`${style.cardInfo}`}>{estagio.Curso_Desejado_1}</p>)}
                        <p className={`${style.cardInfo}`}>
                          {estagio.Curso_Desejado_2 ? estagio.Curso_Desejado_2 : "\u00A0"}
                        </p>
                        <p className={`${style.cardInfo}`}>
                          {estagio.Curso_Desejado_3 ? estagio.Curso_Desejado_3 : "\u00A0"}
                        </p>
                        </div>
                    </div>
                    ))}
                  </Carousel>
                  </>
            )}
            </div>
            
            
            <Footer /> 
            <Modal isOpen={modal_C} toggle={toggle_C} className="modal-form">
              <ModalBody>
              <Checkmark size='64px' color='#2C7865' />
              <br></br>
              <h1 className="form-title">Presença registrada com sucesso!</h1>
              <p className="form-description">Sua presença na entrevista foi registrada com sucesso.</p>
              <Button className="login-button" variant="custom" onClick={toggle_C}>Voltar para a página inicial</Button>
              </ModalBody>
            </Modal>
        </>
    );
}

export default PrincipalIndex;
