import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';

import PrincipalIndex from './pages/index.jsx'
import EstudantePage from './pages/estudante/estudantePage.jsx';
import EmpresaPage from './pages/empresa/empresaPage.jsx'
import UserContext from './UserContext.js';
import VagaContent from './pages/empresa/vagacontent.jsx';
import Perfil_Estudante from './pages/estudante/plataforma.jsx';
import SettingPageEstudante from './pages/estudante/settingPage.jsx';
import PaginaDetalhesEstagio from './components/estudante/plataforma/DadosVaga.jsx';
import Estudantes_Candidatados from './components/empresa/plataforma/Detalhe_Vaga.jsx';
import SettingPageEmpresa from './pages/empresa/settingPage.jsx';
import Verificador from './components/outros-components/verification_email.jsx';
import Verificador_Empresa from './components/outros-components/verification_email_empresa.jsx';
import ChangeStatus from './components/outros-components/verification_estagiario_vaga.jsx';
import NotFound from "./pages/tratament/notFoundPageSystem/notFoundCont.jsx";

function App() {
  const [userInfo, setUserInfo] = useState(null);

  return (

    <Router>
      <div>
      <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
        <UserContext.Provider value={{ userInfo, setUserInfo }}>
          <Routes>
            <Route path="/" element={<PrincipalIndex />}/>
            <Route path="/estudante" element={<EstudantePage />} /> 
            <Route path="/empresa" element={<EmpresaPage />} />       
            <Route path="/area" element={<Perfil_Estudante />}  />
            <Route path="/configEmpresa" element={<SettingPageEmpresa />} />
            <Route path="/configEstudante" element={<SettingPageEstudante />} />
            <Route path="/DadosVaga" element={<PaginaDetalhesEstagio />} />
            <Route path="/Estudantes_Candidatados" element={<Estudantes_Candidatados />} />
            <Route path="/changestatus" element={<ChangeStatus />} />
            <Route path="/vaga" element={<VagaContent />} />
            <Route path="/verify-empresa" element={<Verificador_Empresa />} />
            <Route path="/verify" element={<Verificador />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </UserContext.Provider>
      </div>
    </Router>
  );
}

export default App;
