import React, { useState } from 'react';
import { Modal, Button, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import './TermosCoditionsEmpresa.module.css';

function TermsModal({ onTermsAccepted,isTermosAceitos }) {
    const [showPrivacy, setShowPrivacy] = useState(false);
    const [showTerms, setShowTerms] = useState(false);
    const [isTermsAccepted, setIsTermsAccepted] = useState(false);
    const [showTooltip, setShowTooltip] = useState(false);

    const handleClosePrivacy = () => setShowPrivacy(false);
    const handleShowPrivacy = () => setShowPrivacy(true);
  
    const handleCloseTerms = () => setShowTerms(false);
    const handleShowTerms = () => setShowTerms(true);

    const handleAcceptanceChange = (e) => {
        setIsTermsAccepted(e.target.checked);
        onTermsAccepted(e.target.checked); // Passando o estado atualizado para o componente pai.
        setShowTooltip(!e.target.checked);
    };


    const renderTooltip = (props) => (
      <Tooltip id="button-tooltip" {...props}>
          Preencha este campo.
      </Tooltip>
  );
    
  return (
    <>
      <Form.Group  className="custom-checkbox-wrapper" controlId="formPrivacyCheckbox">
      <OverlayTrigger
                    placement="top"
                    overlay={renderTooltip}
                    show={!isTermosAceitos && !isTermsAccepted}
                >
        <Form.Check  type="checkbox" label={
        <>
          Eu concordo com a <Button variant="link" onClick={handleShowPrivacy} style={{ padding: 0 ,margin: 0, marginRight:'4px', marginBottom:'4px'}}>Política de Privacidade</Button>e 
          <Button variant="link" onClick={handleShowTerms} style={{ padding: 0 ,margin: 0, marginLeft:'4px', marginBottom:'4px'}}>Termos de Uso.</Button>
        </>
          }
          required
          onChange={handleAcceptanceChange}
          checked={isTermsAccepted} // Controla o estado do checkbox
        />
        </OverlayTrigger>
      </Form.Group>

      <Modal show={showPrivacy} onHide={handleClosePrivacy} size="lg" className="modal-form">
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: '22px' }}>Política de Privacidade - LINK ESTÁGIO AGENTE DE INTEGRAÇÃO LTDA</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: 'calc(100vh - 210px)', overflowY: 'auto' }}>
          <p><strong>1. Introdução</strong><br/>
          Esta Política de Privacidade estabelece como a LINK ESTÁGIO AGENTE DE INTEGRAÇÃO LTDA (CNPJ: 36.180.382/0001-20) coleta, utiliza, armazena e protege as informações dos usuários de seus serviços, em conformidade com a Lei Geral de Proteção de Dados (LGPD) e outras legislações aplicáveis.          
          </p>
          <p><strong>2. Dados Coletados</strong><br/>
          Coletamos os seguintes dados da empresa para a realização de nossos serviços: Nome da Empresa; Endereço Completo; CNPJ; Telefone/Celular; E-mail; Nome do Representante da Empresa e CPF. Estes dados são essenciais para o cadastro, seleção e administração dos programas de estágio.
          </p>          
          <p><strong>3. Representante para fins de LGPD (DPO)</strong><br/>
          O Data Protection Officer (DPO) da empresa é Robério Henrique Costa, CPF 348.913.583-00. Ele é responsável por monitorar a conformidade com a LGPD e será o ponto de contato para usuários em questões relativas à proteção de dados.          
          </p>
          <p><strong>4. Uso dos Dados</strong><br/>
          Os dados coletados são usados exclusivamente para os fins de intermediação de estágios, incluindo, mas não limitado a, recrutamento, seleção e administração de programas de estágio.          </p>
          <p><strong>5. Compartilhamento de Dados</strong><br/>
          Não compartilhamos seus dados com terceiros, exceto quando necessário para a prestação de nossos serviços ou por exigência legal.</p>
          <p><strong>6. Segurança dos Dados</strong><br/>
          Empregamos medidas de segurança robustas para proteger os dados da empresa contra acesso não autorizado, alteração, divulgação ou destruição.</p>
          <p><strong>7. Acesso e Controle dos Dados</strong><br/>
          O usuário tem o direito de acessar, corrigir, deletar ou solicitar a portabilidade de seus dados. Qualquer solicitação pode ser dirigida ao nosso DPO.</p>
          <p><strong>8. Retenção de Dados</strong><br/>
          Retemos os dados da empresa apenas pelo tempo necessário para cumprir os propósitos para os quais foram coletados, a menos que uma retenção mais longa seja exigida ou permitida por lei.</p>
          <p><strong>9. Alterações na Política de Privacidade</strong><br/>
          Reservamo-nos o direito de modificar esta Política de Privacidade a qualquer momento. Qualquer alteração entrará em vigor imediatamente após sua publicação no site.</p>
          <p><strong>10. Cookies e Tecnologias Semelhantes</strong><br/>
          Podemos utilizar cookies e tecnologias similares para melhorar a experiência do usuário em nosso site. O uso dessas tecnologias será em conformidade com as preferências do usuário e a legislação aplicável.</p>
          <p><strong>11. Contato</strong><br/>
          Para quaisquer dúvidas ou preocupações relacionadas à proteção de dados, entre em contato com nosso DPO, Robério Henrique Costa, através dos canais fornecidos no site.</p>
          <p><strong>12. Jurisdição e Lei Aplicável</strong><br/>
          Esta Política de Privacidade é regida pelas leis do Brasil. Quaisquer disputas relacionadas a esta política serão resolvidas na jurisdição da sede da empresa.</p>


        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" className="login-button" onClick={handleClosePrivacy}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showTerms} onHide={handleCloseTerms} size="lg" className="modal-form">
      <Modal.Header closeButton>
          <Modal.Title>Termos de Uso - LINK ESTAGIO AGENTE DE INTEGRACAO LTDA</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: 'calc(100vh - 210px)', overflowY: 'auto' }}>
        <p><strong>1. Aceitação dos Termos</strong><br/>
        Ao acessar e utilizar os serviços da LINK ESTÁGIO AGENTE DE INTEGRAÇÃO LTDA (CNPJ: 36.180.382/0001-20), você concorda em cumprir estes Termos de Uso.
        Estes Termos aplicam-se a todos os usuários dos serviços, incluindo sem limitação aos empregadores, estudantes e instituições de ensino.</p>
        <p><strong>2. Descrição dos Serviços</strong><br/>
        Oferecemos um serviço de intermediação entre empresas e estudantes para oportunidades de estágio. Nossa plataforma digital facilita o recrutamento, a seleção e a administração de estagiários, proporcionando agilidade e eficiência no processo.</p>
        <p><strong>3. Registro e Confidencialidade</strong><br/>
        Para utilizar nossos serviços, é necessário um registro, onde informações das empresas e pessoais serão solicitadas. Asseguramos a confidencialidade e a segurança desses dados, em conformidade com nossa Política de Privacidade.</p>
        <p><strong>4. Responsabilidades do Usuário</strong><br/>
        É responsabilidade do usuário garantir a veracidade, precisão e atualização das informações fornecidas. O uso indevido ou informações falsas podem resultar em suspensão ou término do acesso aos nossos serviços.</p>
        <p><strong>5. Direitos Autorais e Propriedade Intelectual</strong><br/>
        Todo o conteúdo e estrutura do site e serviços são propriedade da LINK ESTÁGIO e protegidos por leis de direitos autorais. O uso não autorizado de qualquer material disponível no site é estritamente proibido.</p>
        <p><strong>6. Uso Aceitável</strong><br/>
        Os usuários devem utilizar nossos serviços de maneira ética, respeitando todas as leis aplicáveis. Qualquer uso indevido, fraudulento ou que viole estes termos resultará em suspensão ou término do acesso.</p>
        <p><strong>7. Limitação de Responsabilidade</strong><br/>
        Não nos responsabilizamos por danos indiretos, incidentais ou consequenciais, incluindo perda de lucros, relacionados ao uso de nossos serviços.</p>
        <p><strong>8. Indenização</strong><br/>
        O usuário concorda em indenizar e isentar a LINK ESTÁGIO de qualquer reivindicação, perda, dano ou despesa, incluindo honorários advocatícios, decorrentes do uso indevido dos serviços.</p>
        <p><strong>9. Modificações dos Termos</strong><br/>
        Reservamo-nos o direito de modificar estes Termos de Uso a qualquer momento. As alterações entrarão em vigor imediatamente após a publicação no site.</p>
        <p><strong>10. Rescisão</strong><br/>
        Reservamo-nos o direito de suspender ou terminar o acesso de qualquer usuário aos nossos serviços por violação destes Termos.</p>
        <p><strong>11. Garantias</strong><br/>
        Não fornecemos garantias expressas ou implícitas relacionadas à disponibilidade, funcionalidade ou confiabilidade dos nossos serviços.</p>
        <p><strong>12. Resolução de Conflitos</strong><br/>
        Qualquer disputa relacionada a estes Termos será resolvida através de arbitragem, de acordo com as leis brasileiras, na jurisdição da sede da empresa.</p>
        <p><strong>13. Avisos</strong><br/>
        Qualquer aviso relacionado a estes Termos deve ser enviado por escrito e será considerado entregue na data de envio.</p>
        <p><strong>14. Independência das Cláusulas</strong><br/>
        Se qualquer cláusula destes Termos for considerada inválida ou inaplicável, as demais cláusulas permanecerão em vigor.</p>
        <p><strong>15. Acordo Integral</strong><br/>
        Estes Termos constituem o acordo integral entre o usuário e a LINK ESTÁGIO AGENTE DE INTEGRAÇÃO LTDA, substituindo quaisquer acordos anteriores relacionados ao uso dos nossos serviços.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" className="login-button" onClick={handleCloseTerms}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default TermsModal;