import styles from './empresaPage.module.css';
import LoginComponent from '../../components/empresa/loginForm.jsx'
import RegisterComponent from '../../components/empresa/registerForm.jsx'
import Header from '../../components/Header/headerForm_Empresa.jsx'
import Footer from '../../components/Footer/FooterForm.jsx';

import React, { useState,useEffect } from 'react';


const LoginForm = () => {

  useEffect(() => {
    window.gtag('event', 'conversion', {
      'send_to': 'AW-16651989164/uwO2CLTD4sUZEKzhpIQ-',
      'value': 1.0,
      'currency': 'BRL'
    });
  }, []);
  
  const [isLoginModalOpen, setisLoginModalOpen] = useState(false);

  const toggleRegisterModal = () => {
    setisLoginModalOpen(!isLoginModalOpen);
  };

  const [isRegisterModalOpen, setisRegisterModalOpen] = useState(false);

  const toggleLoginModal = () => {
    setisRegisterModalOpen(!isRegisterModalOpen);
  };
  
  return (

    <>
    <Header />
    <div className={styles.largeDiv}></div> {/* Adicione a classe aqui */}

    <div className={styles.fullBackground}>

      <div className={styles.loginRegisterContainer}>
          <LoginComponent 
           isLoginModalOpen={isLoginModalOpen} 
           toggleRegisterModal={toggleRegisterModal} 
           onLoginSuccess={toggleLoginModal}
           />
          <RegisterComponent 
          isRegisterModalOpen={isRegisterModalOpen} 
          toggleLoginModal={toggleLoginModal} 
          onRegisterSuccess={toggleRegisterModal}/>
        </div>
      </div>
    <Footer />
    </>
  );
};

export default LoginForm;
