import React, { useState,useEffect } from 'react';
import { Button, Modal, Form, Label, FormGroup, Input, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import axios from 'axios';
import './formStyleRegisterLogin.css';
import { useNavigate } from 'react-router-dom';
import { FaEye, FaEyeSlash } from 'react-icons/fa'; 
import { Checkmark } from 'react-checkmark'

const LoginForm = ({ isLoginModalOpen, toggleRegisterModal, onLoginSuccess }) => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({ email: '', senha: '' });
  const [ResetData, setResetData] = useState({ resetEmail: ''});
  const [modal, setModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [loginModal, setLoginModal] = useState(false);
  const [resetPasswordModal, setResetPasswordModal] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState('');
  const [resetPasswordNewModal, setResetPasswordNewModal] = useState(false);
  const [newPasswordData, setNewPasswordData] = useState({ password: '', confirmPassword: '' });
  const [passwordError, setPasswordError] = useState('');
  const [isContaExiste, setContaExiste] = useState(false);
  const [isPasswordVisible, setPasswordVisible] = useState(false);
  const [isPasswordVisible1, setPasswordVisible1] = useState(false);
  const [isPasswordVisible2, setPasswordVisible2] = useState(false);
  const [modal_C, setModal_C] = useState(false);
  const toggle_C = () => setModal_C(!modal_C);

  const togglePasswordVisibility = () => {
    setPasswordVisible(prev => !prev);
  };

  const togglePasswordVisibility1 = () => {
    setPasswordVisible1(prev => !prev);
  };

  const togglePasswordVisibility2 = () => {
    setPasswordVisible2(prev => !prev);
  };

  const toggleLoginModal = () => setLoginModal(!loginModal);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleChangeReset = (event) => {
    const { name, value } = event.target;
    console.log(ResetData)
    setResetData({ ...ResetData, [name]: value });
  };

  useEffect(() => {

    setTimeout(() => {
      setLoginModal(isLoginModalOpen);
    }, 350);
  }, [isLoginModalOpen]);

  const handleResetPassword = (event) => {
    event.preventDefault();
    

    axios.post('https://linkestagio.com.br/App.php?operation=ResetPassword-empresa1', ResetData)
    .then((response) => {
      if (response.data !== '') {
        setFeedbackMessage('Email enviado com sucesso! Verifique sua caixa de entrada.');


        axios.post('https://linkestagio.com.br/App.php?operation=ResetPassword-empresa2', ResetData)
        .then((response2) => {
        })
        .catch((error2) => {
          // Tratar erro do segundo axios.post
        });


      } else {
        setFeedbackMessage('Não foi possivel enviar o email, tente novamente em alguns instantes.');
      }
    })
    .catch((error) => {
      setFeedbackMessage('Erro de conexão com o servidor.');
    });
};

  const toggleResetPasswordModal = () => {
    setLoginModal(false); // Fecha explicitamente o modal de login
    setResetPasswordModal(!resetPasswordModal); // Isso alternará o estado atual, permitindo abrir e fechar o modal
  };
  
  const handleLogin = () => {
    toggleLoginModal();
    onLoginSuccess(); // Isso abrirá o modal de confirmação no LoginComponent
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    

    axios.post('https://linkestagio.com.br/App.php?operation=login-empresa', formData)
      .then((response) => {
        console.log(response.data); // Adicionado para inspecionar a resposta do servidor
        if (response.data.message !== null && typeof response.data === 'object' && !Array.isArray(response.data) && response.data.message == "Login bem-sucedido.") {
          const userData = response.data.data;
          sessionStorage.removeItem('EmpresaInfo');
          sessionStorage.setItem('EmpresaInfo', JSON.stringify(userData)); // Armazena userData no localStorage          
          navigate('/vaga'); // Redireciona para a página de perfil
        } else {
          setContaExiste(true);
          console.error('Erro de login:', response.data.message);
        }
      })
      .catch((error) => {
        console.error('Erro de login:', error);
      });
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const email = urlParams.get('email');
    const token = urlParams.get('token');

    if (email && token) {
      // Fazer chamada de API para validar
      axios.post('https://linkestagio.com.br/App.php?operation=CheckPassword-empresa', { email, token })
        .then(response => {
          console.log(response.data)
          if (response.data == 'O token é válido e o hash corresponde.') {
            setResetPasswordNewModal(true);
          }else{
            console.log(response.data)
          }
        })
        .catch(error => console.error(error));
    }
  }, []);

  const handleChangeNewPassword = (event) => {
    const { name, value } = event.target;
    setNewPasswordData({ ...newPasswordData, [name]: value });
  };

  // Aqui vai a função para submeter a nova senha
  const handleSubmitNewPassword = (event) => {
    event.preventDefault();
    const urlParams = new URLSearchParams(window.location.search);

    const email = urlParams.get('email');

    if (newPasswordData.password !== newPasswordData.confirmPassword) {
      setPasswordError('As senhas não coincidem.');
      return; // Interrompe a execução se as senhas não coincidirem
    }

    const dataToSend = { ...newPasswordData, email }; // Adiciona o email ao objeto de dados

    axios.post('https://linkestagio.com.br/App.php?operation=NewPassword-empresa', dataToSend)
    .then(response => {
      console.log(response)
    })

    setResetPasswordNewModal(!resetPasswordNewModal);
    setTimeout(() => {
      toggle_C(true);
    }, 450);


  };
  

  return (
    <div>
        <div className="button-container">
          <Button className="access-button" onClick={toggleLoginModal}>
            <span className="access-sub-text">Acessar</span>
          </Button>
        </div>
        <Modal isOpen={loginModal} toggle={toggleLoginModal}>
          
        <ModalHeader style={{ paddingBottom: 0 , borderBottom: 'none' }} toggle={toggleLoginModal}></ModalHeader>
          <ModalBody>
          <Form onSubmit={handleSubmit} className="modal-form">
                    <h1 className="form-title">Acesse a Página</h1>
                    <p className="form-description">Acesse a Plataforma Link Estágio.</p>

                    <div className="input-block">
                        <Label for="email" className="linkLabel">Email</Label>
                        <Input
                          type="email"
                          name="email"
                          id="email"
                          placeholder="Email"
                          value={formData.email}
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <div className="input-block" style={{ position: 'relative' }}>
                      <Label for="email" className="linkLabel">Senha</Label>
                        <Input
                          name="senha"
                          id="senha"
                          placeholder="Senha"
                          type={isPasswordVisible ? 'text' : 'password'}
                          value={formData.senha  || ''}
                          onChange={(e) => setFormData({ ...formData, senha: e.target.value })}
                          required
                        />
                  <button
                    onClick={togglePasswordVisibility}
                    style={{
                      position: 'absolute',
                      right: '15px',
                      top: '70%',
                      transform: 'translateY(-50%)',
                      border: 'none',
                      background: 'transparent',
                      cursor: 'pointer'
                    }}
                    type="button"
                  >
                    {isPasswordVisible ? <FaEyeSlash /> : <FaEye />}
                  </button>
                      </div>
                      <a href="#" className="forgot-password" onClick={toggleResetPasswordModal}>Esqueceu sua Senha?</a>
                      
                    


                      <Button className="login-button" variant="" type="submit">Login</Button>
                      {isContaExiste && (
                        <div style={{ color: 'red'}}>
                            Erro de login ou conta não verificada.
                        </div>
                      )}
                    <p className="sign-up-text">
                    Não tem uma conta? <a href="#" onClick={handleLogin}>Faça seu cadastro!</a>
                    </p>
                </Form>
      </ModalBody>
    </Modal>
    <Modal isOpen={resetPasswordModal} toggle={toggleResetPasswordModal}>
    <ModalHeader style={{ paddingBottom: 0 , borderBottom: 'none' }} toggle={toggleResetPasswordModal}></ModalHeader>

        <ModalBody>
          
          <Form onSubmit={handleResetPassword}  className="modal-form">
            <h1 className="form-title">Recuperar Senha</h1>
            <p className="form-description">Digite seu email para receber o email de recuperação de senha.</p>
            <FormGroup>
            <div className="input-block">
              <Label for="resetEmail">Email</Label>
              <Input
                type="email"
                name="resetEmail"
                id="resetEmail"
                placeholder="Digite seu email"
                onChange={handleChangeReset}
                required
              />
              </div>
            </FormGroup>
            <Button className="login-button" variant="" type="submit">Enviar email de recuperação</Button>
            {feedbackMessage && <p className="form-description">{feedbackMessage}</p>}
          </Form>
        </ModalBody>
      </Modal>
      
      <Modal isOpen={resetPasswordNewModal} toggle={() => setResetPasswordNewModal(!resetPasswordNewModal)}>
      <ModalHeader style={{ paddingBottom: 0 , borderBottom: 'none' }} toggle={() => setResetPasswordNewModal(!resetPasswordNewModal)}></ModalHeader>
        <ModalBody>
          <Form onSubmit={handleSubmitNewPassword} className="modal-form">
            <h1 className="form-title">Redefinir Senha</h1>
            <p className="form-description">Digite sua nova senha para acessar a plataforma</p>
            <FormGroup>
            <div className="input-block" style={{ position: 'relative' }}>
              <Label for="password">Nova Senha</Label>
              <Input type={isPasswordVisible1 ? 'text' : 'password'} name="password" id="password" 
              placeholder="Nova Senha" onChange={handleChangeNewPassword} required />
              <button
                    onClick={togglePasswordVisibility1}
                    style={{
                      position: 'absolute',
                      right: '15px',
                      top: '70%',
                      transform: 'translateY(-50%)',
                      border: 'none',
                      background: 'transparent',
                      cursor: 'pointer'
                    }}
                    type="button"
                  >
                    {isPasswordVisible1 ? <FaEyeSlash /> : <FaEye />}
                  </button>
          
            </div>
            </FormGroup>
            <FormGroup>
            <div className="input-block" style={{ position: 'relative' }}>

              <Label for="confirmPassword">Confirmar Nova Senha</Label>
              <Input type={isPasswordVisible2 ? 'text' : 'password'} name="confirmPassword" id="confirmPassword"
               placeholder="Confirmar Nova Senha" onChange={handleChangeNewPassword} required />
              <button
                    onClick={togglePasswordVisibility2}
                    style={{
                      position: 'absolute',
                      right: '15px',
                      top: '70%',
                      transform: 'translateY(-50%)',
                      border: 'none',
                      background: 'transparent',
                      cursor: 'pointer'
                    }}
                    type="button"
                  >
                    {isPasswordVisible2 ? <FaEyeSlash /> : <FaEye />}
                  </button>
            
            </div>
            </FormGroup>
            <Button className="login-button" variant="" type="submit">Redefinir Senha</Button>
          </Form>
        </ModalBody>
      </Modal>
      <Modal isOpen={modal_C} toggle={toggle_C} className="modal-form">
        <ModalBody>
        <Checkmark size='64px' color='#2C7865' />
        <br></br>
        <h1 className="form-title">Senha Alterada Com Sucesso!</h1>
        <p className="form-description">Você ja pode usar a sua nova senha na plataforma Link Estágio.</p>
        <Button className="login-button" variant="custom" onClick={toggle_C}>Voltar para a página inicial</Button>
        </ModalBody>
      </Modal>
  </div>
  );
}

export default LoginForm;