import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { FaInstagram, FaFacebookF, FaPhone, FaWhatsapp } from 'react-icons/fa'; // Importe os ícones adicionais
import style from './footerForm.module.css';

function Footer() {
  return (
    <footer className={style.footer}>
      <Container>
        <Row className={style.footerLinkPage}>
          <Col>
            <a href="http://www.planalto.gov.br/ccivil_03/_ato2007-2010/2008/lei/l11788.htm" 
               target="_blank" 
               rel="noopener noreferrer" 
               className={style.legalButton}>
              Consulte a lei do estágio: Lei Nº 11.788, de 25 de Setembro de 2008
            </a>
          </Col>
        </Row>
        <Row className="justify-content-between">
          <Col xs="12" md="4" className={`${style.footerContact} text-md-right`}>
            <h5 className={style.contactTitle}>Contatos</h5>
            <p className={style.contactItem}>contato@linkestagio.com.br</p>
            <p className={style.contactItem}>
              <FaPhone className={style.contactIcon} />
              <FaWhatsapp className={style.contactIcon} />
              (85) 99732-5336
            </p>
          </Col>

          <Col xs="12" md="4" className={`${style.footerSocial} text-md-right`}>
          <h5 className={style.socialTitle}>Siga nossas Redes Sociais</h5>
            <a href="https://www.instagram.com/linkestagio/" target="_blank" rel="noopener noreferrer" className={style.contactItem}>
              <FaInstagram  className={style.contactIcon} />Instagram
            </a>

            <p className={style.contactItem}>
              <a href="https://www.instagram.com/linkestagio/" target="_blank" rel="noopener noreferrer" className={style.contactItem}>
                <FaFacebookF className={style.contactIcon2} />
                Facebook
              </a>
            </p>


          </Col>
          
          

        </Row>
        <Row>
          <Col className={style.footerRights}>
            <p>Todos os direitos reservados - Link Estágio ©</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
