import React, { useState, useEffect } from 'react';
import { Modal, Tab, Nav, Button } from 'react-bootstrap';
import {Form, Label, FormGroup, Input, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { FaEye, FaEyeSlash } from 'react-icons/fa'; 
import { Checkmark } from 'react-checkmark'
import TextField from '@mui/material/TextField';
import InputMask from 'react-input-mask'; 
import TermsModal from './TermosCoditionsEmpresa.jsx';
import axios from 'axios';


const LoginForm = ({ isRegisterModalOpen, toggleLoginModal, onRegisterSuccess }) => {

  const [inputData, setInputData] = useState({ Nome_Empresa: '',RuaEditable:false, Representante_Empresa: '',Telefone_Empresa:'',CPF_Representante:'', Rua:'',Numero:'', CEP_Empresa: '', CNPJ: '', email: '', c_email: '', senha: '', c_senha: '' ,a:''});
  const [showModal, setShowModal] = useState(false);
  const [isCpfInvalid, setIsCpfInvalid] = useState(false);
  const [isCnpjInvalid, setIsCnpjInvalid] = useState(false);
  const [isCepInvalid, setIsCepInvalid] = useState(false);
  const [isPasswordVisible, setPasswordVisible] = useState(false);
  const [isCPasswordVisible, setCPasswordVisible] = useState(false);
  const [isEmailInvalid, setIsEmailInvalid] = useState(false);
  const [isPasswordInvalid, setIsPasswordInvalid] = useState(false);
  const [isTermosAceitos, seTermosAceitos] = useState(false);
  const [isTermosAceitos2, seTermosAceitos2] = useState(true);

  const [isContaExiste, setContaExiste] = useState(false);
  const [isTelefoneInvalid, setIsTelefoneInvalid] = useState(false);


  const [modal_C, setModal_C] = useState(false);
  const toggle_C = () => setModal_C(!modal_C);

  const [errors, setErrors] = useState({
    CEP_Empresa: false,
    CNPJ: false,
    CPF_Representante: false,
    Nome_Empresa: false,
    Numero: false,
    Representante_Empresa: false,
    Rua: false,
    c_email: false,
    c_senha: false,
    email: false,
    senha: false,
    Telefone_Empresa: false
  });

  const togglePasswordVisibility = () => {
    setPasswordVisible(prev => !prev);
  };

  const toggleCPasswordVisibility = () => {
    setCPasswordVisible(prev => !prev);
  };


  const handleTermsAccepted = (accepted) => {
    seTermosAceitos(accepted);
  };

  useEffect(() => {

    setTimeout(() => {
      setShowModal(isRegisterModalOpen);
    }, 350);
  }, [isRegisterModalOpen]);

  const handleRegister = () => {
    toggleShowModal();
    onRegisterSuccess(); // Isso abrirá o modal de confirmação no LoginComponent
  };

  const toggleShowModal = () => setShowModal(!showModal);

  function validaCNPJ(cnpj) {
    cnpj = cnpj.replace(/[^\d]+/g, ''); // Remove caracteres não numéricos
    if (cnpj.length !== 14) return false; // Verifica o tamanho
    if (cnpj == "00000000000000" || 
    cnpj == "11111111111111" || 
    cnpj == "22222222222222" || 
    cnpj == "33333333333333" || 
    cnpj == "44444444444444" || 
    cnpj == "55555555555555" || 
    cnpj == "66666666666666" || 
    cnpj == "77777777777777" || 
    cnpj == "88888888888888" || 
    cnpj == "99999999999999")
    return false;
  
    let tamanho = cnpj.length - 2;
    let numeros = cnpj.substring(0, tamanho);
    const digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) pos = 9;
    }
    let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado !== parseInt(digitos.charAt(0))) return false;
  
    tamanho = tamanho + 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado !== parseInt(digitos.charAt(1))) return false;
  
    return true;
  }

  function validaCPF(cpf) {
    cpf = cpf.replace(/[^\d]+/g, ''); // Remove caracteres não numéricos
    if (cpf.length !== 11 || /^(\d)\1{10}$/.test(cpf)) return false; // Verifica o tamanho e sequências iguais

    let soma = 0;
    for (let i = 0; i < 9; i++) soma += parseInt(cpf.charAt(i)) * (10 - i);
    let resto = 11 - (soma % 11);
    if (resto === 10 || resto === 11) resto = 0;
    if (resto !== parseInt(cpf.charAt(9))) return false;

    soma = 0;
    for (let i = 0; i < 10; i++) soma += parseInt(cpf.charAt(i)) * (11 - i);
    resto = 11 - (soma % 11);
    if (resto === 10 || resto === 11) resto = 0;
    if (resto !== parseInt(cpf.charAt(10))) return false;

    return true;
  }

    function validaTelefone(telefone) {
      telefone = telefone.trim();
      const regex = /^\(\d{2}\) \d{8,9}$/;
      return regex.test(telefone);
  }

  function validatePassword(password, confirmPassword) {
    const hasMinLength = password.length >= 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumbers = /[0-9]/.test(password);
    const isMatch = password === confirmPassword;
  
    return hasMinLength && hasUpperCase && hasLowerCase && hasNumbers && isMatch;
  }

  function validateEmails(email, confirmEmail) {
    return email === confirmEmail;
  }
  

  const handleSubmit = (event) => {
    event.preventDefault();
    setContaExiste(false)
    var Erro = 0;


    const requiredFields = [
      'CEP_Empresa',
      'CNPJ',
      'CPF_Representante',
      'Nome_Empresa',
      'Numero',
      'Representante_Empresa',
      'Rua',
      'c_email',
      'c_senha',
      'email',
      'senha',
      'Telefone_Empresa'
      ];  


    const validFields = requiredFields.filter(field => inputData[field] && inputData[field].trim() !== '');

    validFields.forEach(field => {
        errors[field] = false;
    });


    const invalidFields = requiredFields.filter(field => !inputData[field] || inputData[field].trim() === '');
    const isEveryFieldValid = invalidFields.length === 0;


    invalidFields.forEach(field => {
      errors[field] = true;
    });

    setInputData(prevState => ({
      ...prevState
    }));

    if(!isTermosAceitos){
      seTermosAceitos2(false);
    }else{
      seTermosAceitos2(true);

    }

    if (Erro != 0 || !isEveryFieldValid || !isTermosAceitos){
      console.log("ERro");
      return;

    }


    if (validaCPF(inputData['CPF_Representante'])) {
      errors['CPF_Representante'] = false;
    } else {
      errors['CPF_Representante'] = true;
      Erro += 1;
    }
    

    if (validaCNPJ(inputData['CNPJ'])) {
      errors['CNPJ'] = false;
    } else {
      errors['CNPJ'] = true;
      Erro += 1;

    }

    if (validatePassword(inputData['senha'], inputData['c_senha']))  {

      //setIsPasswordInvalid(false)
      errors['c_senha'] = false;

    } else {
      //setIsPasswordInvalid(true)
      errors['c_senha'] = true;

      Erro += 1;
    }

    if (validateEmails(inputData['email'], inputData['c_email'])) {
      //setIsEmailInvalid(false);
      errors['c_email'] = false;

    } else {
      //setIsEmailInvalid(true);
      errors['c_email'] = true;

      Erro += 1;
    }

    if (!validaTelefone(inputData['Telefone_Empresa'])) {
      //setIsTelefoneInvalid(true);
      errors['Telefone_Empresa'] = true;

      Erro += 1;
    } else {
      errors['Telefone_Empresa'] = false;

    }

    setInputData(prevState => ({
      ...prevState
    }));

    if (Erro != 0 ){
      return;
    }

    axios.post('https://linkestagio.com.br/App.php?operation=check-data-cadastro-empresa', { ...inputData }, {
      headers: { 'Content-Type': 'application/json' },
    })
    .then((response) => {

      if (response.data == "Usuário já existe."){
        setContaExiste(true)
      }else{
        
        axios.post('https://linkestagio.com.br/App.php?operation=add-data-cadastro-empresa1', { ...inputData }, {
          headers: { 'Content-Type': 'application/json' },
        })
        .then((response) => {
          if(response){
            setTimeout(() => {
              toggle_C(true);
            }, 450);
            axios.post('https://linkestagio.com.br/App.php?operation=add-data-cadastro-empresa2', { ...inputData })
          }
        })
        .catch((error) => {
          console.error('Erro:', error);
        });


        setContaExiste(false)
        toggleShowModal();


        setTimeout(() => {
          toggle_C(true);
        }, 450);
      
      }})
      .catch((error) => {
        setContaExiste(true)
        console.error('Erro:', error);
        return;
      });


    /*
    axios.post('https://linkestagio.com.br/App.php?operation=add-data-cadastro-empresa1', { ...inputData }, {
        headers: { 'Content-Type': 'application/json' },
      })
      .then((response) => {
        if(response){
          setTimeout(() => {
            toggle_C(true);
          }, 450);
          axios.post('https://linkestagio.com.br/App.php?operation=add-data-cadastro-empresa2', { ...inputData })
        }
      })
      .catch((error) => {
        console.error('Erro:', error);
      });

      */
    
      
  };


  const handleChange = (event) => {
    const { name, value } = event.target;
    var cep_limpo = value.replace('-', '').replace(/_/g, '');


    if (name === "CEP_Empresa" && cep_limpo.length === 8) {

        axios.post('https://linkestagio.com.br/App.php?operation=get-localizacao', { CEP: value }, {
            headers: { 'Content-Type': 'application/json' }
        })
        .then(response => {

          if (!('erro' in response.data)) {
            setInputData(prevFormData => ({
              ...prevFormData,
              Rua: response.data.logradouro,
              RuaEditable: response.data.logradouro === ''
            }));

          }else{
            setInputData(prevFormData => ({
              ...prevFormData,
              Rua: '',
              RuaEditable:false
            }));
          }

        })
        .catch(error => {
            console.error('Erro ao buscar dados do CEP:', error);
        });
    }
    setInputData(prevFormData => ({
        ...prevFormData,
        [name]: value
    }));
};

  


  return (
    <>
    
    <Modal show={modal_C} toggle={toggle_C} onHide={toggle_C} centered>
      <ModalHeader style={{ borderBottom: 'none' }} toggle={toggle_C}>

      </ModalHeader>
        <Checkmark size='64px' color='#2C7865' />

        <ModalBody>
        <br></br>
        <h1 className="form-title">Inscrição Realizado Com Sucesso!</h1>
        <p className="form-description">Seu Convênio está em análise. Em alguns Instantes você será notificado no e-mail e poderá acessar a plataforma Link Estágio.</p>
        <Button className="login-button" variant="custom" onClick={toggle_C}>Voltar para a página inicial.</Button>
        </ModalBody>
    </Modal>


    <div className="button-container">
      <Button className="access-button" onClick={() => toggleShowModal(false)}>
        <span className="access-sub-text">Cadastrar</span>
      </Button>
    </div>
      <Modal show={showModal} onHide={() => toggleShowModal()} centered>
      <ModalHeader style={{ paddingBottom: 0 , borderBottom: 'none' }} toggle={toggleShowModal}>
      <h1 className="form-title">Realize seu Cadastro</h1>

      </ModalHeader>

        <ModalBody >
              <Form className="modal-form" onSubmit={handleSubmit}>
              <FormGroup>
                <div className="input-row">
                  <div className="input-block half">

                    <TextField
                      id="Nome_Empresa"
                      name="Nome_Empresa"
                      label="Nome da Empresa *"
                      placeholder=""
                      type="text"
                      className={`form-control ${errors['Nome_Empresa'] ? 'is-invalid' : ''}`}
                      onChange={(e) => setInputData({ ...inputData, Nome_Empresa: e.target.value })}
                      size="small"
                      style={{ marginBottom: '5px',marginTop: '5px' }}
                      InputLabelProps={{
                        required: false // Remove o asterisco do label
                      }}
                      required
                    />

                  </div>
                  <div className="input-block half">
                  
                  <TextField
                    label="CEP da Sede"
                    value={inputData.CEP_Empresa || ''}
                    onChange={handleChange}
                    className={`form-control ${errors['CEP_Empresa'] ? 'is-invalid' : ''}`}
                    id="CEP_Empresa"
                    name="CEP_Empresa"
                    placeholder="CEP da Sede"
                    required
                    size="small"
                    style={{ marginBottom: '5px', marginTop: '5px' }}
                    InputProps={{
                      inputComponent: InputMask,
                      inputProps: {
                        mask: "99999-999",
                        maskChar: " ",
                        value: inputData.CEP_Empresa,
                        onChange: handleChange,
                      }
                    }}
                  />
          
                  </div>
                </div>

                <div className="input-row">
                  <div className="input-block half">


                  <TextField
                    id="Rua"
                    name="Rua"
                    label="Rua da Sede *"
                    placeholder=""
                    type="text"
                    value={inputData.Rua}
                    className={`form-control ${errors['Rua'] ? 'is-invalid' : ''}`}
                    onChange={(e) => {
                      // Permite edição apenas se o campo estiver vazio
                      if (inputData.RuaEditable) {
                        setInputData((prevData) => ({
                          ...prevData,
                          Rua: e.target.value,
                        }));
                      }
                    }}
                    size="small"
                    style={{ marginBottom: '5px', marginTop: '5px', color: 'black' }} // Aplicando estilo diretamente ao campo
                    InputLabelProps={{
                      required: false, // Remove o asterisco do label
                    }}
                    required
                  />     

                  </div>
                  <div className="input-block half">

                  <TextField
                    id="Numero"
                    name="Numero"
                    label="Número da Sede *"
                    placeholder=""
                    type="number"
                    className={`form-control ${errors['Numero'] ? 'is-invalid' : ''}`}
                    onChange={(e) => setInputData({ ...inputData, Numero: e.target.value })}
                    size="small"
                    min="1"
                    max="99999"
                    style={{ marginBottom: '5px', marginTop: '5px'}} // Aplicando estilo diretamente ao campo
                    InputLabelProps={{
                      required: false // Remove o asterisco do label
                    }}
                    required
                  />     

                  </div>
                </div>

                <div className="input-row" >
                  <div className="input-block half">

                  <TextField
                    id="Representante_Empresa"
                    name="Representante_Empresa"
                    className={`form-control ${errors['Representante_Empresa'] ? 'is-invalid' : ''}`}

                    label="Nome do Representante *"
                    placeholder=""
                    type="text"
                    onChange={(e) => setInputData({ ...inputData, Representante_Empresa: e.target.value })}
                    size="small"
                    style={{ marginBottom: '5px', marginTop: '5px', color: 'black' }} // Aplicando estilo diretamente ao campo
                    InputLabelProps={{
                      required: false // Remove o asterisco do label
                    }}
                    required
                  />   


                  </div>
                  <div className="input-block half" >

                  <TextField
                    label="CPF do Representante"
                    value={inputData.CEP_Empresa || ''}
                    onChange={(e) => setInputData({ ...inputData, CPF_Representante: e.target.value })}
                    className={`form-control ${errors['CPF_Representante'] ? 'is-invalid' : ''}`}
                    id="CPF_Representante"
                    name="CPF_Representante"
                    placeholder="CNPJ do Representante"
                    required
                    size="small"
                    style={{ marginBottom: '5px', marginTop: '5px' }}
                    InputProps={{
                      inputComponent: InputMask,
                      inputProps: {
                        mask:"999.999.999-99",
                        maskChar: " ",
                        value: inputData.CPF_Representante,
                        onChange: handleChange,
                      }
                    }}
                  />

                  </div>
                </div>

                <TextField
                  label="CNPJ da Empresa"
                  value={inputData.CNPJ || ''}
                  onChange={(e) => setInputData({ ...inputData, CNPJ: e.target.value })}
                  className={`form-control ${errors['CNPJ'] ? 'is-invalid' : ''}`}
                  id="CNPJ"
                  name="CNPJ"
                  placeholder="CNPJ da Empresa"
                  required
                  size="small"
                  style={{ marginBottom: '5px', marginTop: '5px' }}
                  InputProps={{
                    inputComponent: InputMask,
                    inputProps: {
                      mask:"99.999.999/9999-99",
                      maskChar: " ",
                      value: inputData.CNPJ,
                      onChange: handleChange,
                    }
                  }}
                />
                <p className="form-description" style={{ marginBottom: 0 , marginTop: 0 }}>Esse e-mail será usado para contato e login na plataforma.</p>
                <TextField
                  id="email"
                  name="email"
                  className={`form-control ${errors['email'] ? 'is-invalid' : ''}`}

                  label="E-mail *"
                  placeholder=""
                  type="email"
                  onChange={(e) => setInputData({ ...inputData, email: e.target.value })}
                  size="small"
                  style={{ marginBottom: '5px', marginTop: '5px', color: 'black' }} // Aplicando estilo diretamente ao campo
                  InputLabelProps={{
                    required: false // Remove o asterisco do label
                  }}
                  required
                /> 

                <TextField
                  id="c_email"
                  name="c_email"
                  label="Confirmar E-mail *"
                  placeholder=""
                  type="email"
                  className={`form-control ${errors['c_email'] ? 'is-invalid' : ''}`}
                  onChange={(e) => setInputData({ ...inputData, c_email: e.target.value })}
                  size="small"
                  style={{ marginBottom: '5px', marginTop: '5px', color: 'black' }} // Aplicando estilo diretamente ao campo
                  InputLabelProps={{
                    required: false // Remove o asterisco do label
                  }}
                  required
                />   

                <TextField
                  label="Telefone para Contato"
                  value={inputData.Telefone_Empresa || ''}
                  onChange={(e) => setInputData({ ...inputData, Telefone_Empresa: e.target.value })}
                  className={`form-control ${errors['Telefone_Empresa'] ? 'is-invalid' : ''}`}
                  id="Telefone_Empresa"
                  name="Telefone_Empresa"
                  placeholder="Telefone para Contato"
                  required
                  size="small"
                  style={{ marginBottom: '5px', marginTop: '5px' }}
                  InputProps={{
                    inputComponent: InputMask,
                    inputProps: {
                      mask:"(99) 999999999",
                      maskChar: " ",
                      value: inputData.Telefone_Empresa,
                      onChange: handleChange,
                    }
                  }}
                />

                <p className="form-description" style={{ marginBottom: 0 , marginTop: 0 }}>Senha: mín. 8 caracteres, incluindo maiúsculas, minúsculas e números.</p>

                <div style={{ position: 'relative' }}>

                  <TextField
                    id="senha"
                    name="senha"
                    placeholder=""
                    label="Senha *"
                    type={isPasswordVisible ? 'text' : 'password'}                  
                    className={`form-control ${errors['senha'] ? 'is-invalid' : ''}`}
                    onChange={(e) => setInputData({ ...inputData, senha: e.target.value })}
                    size="small"
                    style={{ marginBottom: '5px',marginTop: '5px' }}
                    required
                  
                    InputLabelProps={{
                      required: false // Remove o asterisco do label
                    }}
                    
                  />
                      <button
                      onClick={togglePasswordVisibility}
                      style={{
                        position: 'absolute',
                        right: errors['senha'] ? '55px' : '15px', // Alterar o valor do 'right' com base na variável booleana
                        top: '50%',
                        transform: 'translateY(-50%)',
                        border: 'none',
                        background: 'transparent',
                        cursor: 'pointer'
                      }}
                      type="button"
                    >
                      {isPasswordVisible ? <FaEyeSlash /> : <FaEye />}
                    </button>
                    </div>

                    <div style={{ position: 'relative' }}>

                      <TextField
                        id="c_senha"
                        name="c_senha"
                        label="Confirmar Senha *"
                        type={isCPasswordVisible ? 'text' : 'password'}                  
                        className={`form-control ${errors['c_senha'] ? 'is-invalid' : ''}`}
                        onChange={(e) => setInputData({ ...inputData, c_senha: e.target.value })}
                        size="small"
                        style={{ marginBottom: '5px',marginTop: '5px' }}
                        required
                        InputLabelProps={{
                          required: false // Remove o asterisco do label
                        }}>
                        
                      </TextField>
                      
                      <button
                        onClick={toggleCPasswordVisibility}
                        style={{
                          position: 'absolute',
                          right: errors['c_senha'] ? '55px' : '15px', // Alterar o valor do 'right' com base na variável booleana
                          top: '50%',
                          transform: 'translateY(-50%)',
                          border: 'none',
                          background: 'transparent',
                          cursor: 'pointer'
                        }}
                        type="button"
                      >
                        {isCPasswordVisible ? <FaEyeSlash /> : <FaEye />}
                      </button>
                    </div>

                  {isEmailInvalid && (
                    <div style={{ color: 'red'}}>
                        Emails são diferentes.
                    </div>
                    )}
                    {isCpfInvalid && (
                        <div style={{ color: 'red' }}>
                            CPF inválido.
                        </div>
                    )}
                    {isCnpjInvalid && (
                        <div style={{ color: 'red'}}>
                            CNPJ inválido.
                        </div>
                    )}
                    {isCepInvalid && (
                        <div style={{ color: 'red'}}>
                            CEP inválido.
                        </div>
                    )}
                    {isPasswordInvalid && (
                        <div style={{ color: 'red'}}>
                            Senhas Diferentes ou Invalidas.
                        </div>
                    )}
                    {isContaExiste && (
                        <div style={{ color: 'red'}}>
                            Usuário ja cadastrado.
                        </div>
                    )}
                    {isTelefoneInvalid && (
                        <div style={{ color: 'red'}}>
                            Telefone inválido.
                        </div>
                    )}

                <TermsModal 
                  isTermosAceitos = {isTermosAceitos2}
                  onTermsAccepted={handleTermsAccepted} />
                <Button className="login-button" variant="custom" onClick={handleSubmit} type="submit">Realizar Cadastro</Button>

                <p className="sign-up-text">
                Já tem uma conta? <a href="#" onClick={handleRegister}>Entrar</a>
                </p>
                </FormGroup>
              </Form>
        </ModalBody>
      </Modal>

    </>
  );
}

export default LoginForm;
