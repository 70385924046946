// 404.js

import React from 'react';
import style from './404.module.css'
import Header from '../../../components/Header/headerForm.jsx'
import Footer from '../../../components/Footer/FooterForm.jsx';

const NotFound = () => {
  return (
    <>
    <Header />
    <div className={`${style.tag_pula}`}>
        <div className={`${style.content}`}>
        <h1>404 - Página não encontrada</h1>
        <p>Oops... Essa página não existe!</p>
        </div>
    </div>
    <Footer />
    </>
  );
}

export default NotFound;