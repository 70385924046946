import React, { useContext, useEffect } from 'react';
import VerticalNavbar from "../../components/verticalHavbarEmpresa/VeticalNavbar2";
import UserConfig from "../../components/empresa/plataforma/userConfig";
import { useNavigate } from 'react-router-dom';

function PlataformaEstudante() {

    const navigate = useNavigate();

    const EmpresaInfo = JSON.parse(sessionStorage.getItem('EmpresaInfo'));

    useEffect(() => {
        if (!EmpresaInfo) {
            navigate('/');
        }
    }, [EmpresaInfo, navigate]);

    if (!EmpresaInfo) return <div></div>;
    
    return (      
        <div>
            <VerticalNavbar userInfo={EmpresaInfo} />
            <UserConfig userInfo={EmpresaInfo} />
        </div>
    );
}

export default PlataformaEstudante;
