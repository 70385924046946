import React, { useState } from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';

import ProfileInfo from './profileInfo';
import style from './verticalNavbar.module.css';
import logo from '../../assets/images/linkEstagio-logo.png';
import { Link } from 'react-router-dom';
import { ReactComponent as HomeSvg } from './../../assets/svg/homeIcon.svg';
import { ReactComponent as ProfileSvg } from './../../assets/svg/profileIcon.svg';
import { ReactComponent as LeaveSvg } from './../../assets/svg/leaveIcon.svg';

const VerticalNavbar = ({userInfo, DadosPreenchidos}) => {
  const [collapsed, setCollapsed] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const isLinkActive = DadosPreenchidos;//userInfo && userInfo.isAuthenticated;  // Supondo que userInfo contém a propriedade isAuthenticated

  const toggleNavbar = () => setCollapsed(!collapsed);

  const handleResize = () => {
    setIsMobile(window.innerWidth < 768);
  };

  React.useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className={style.bodyNavbar}>
      {isMobile ? (
        <Navbar color="faded" light>
          <img
            src={logo}
            style={{ maxWidth: '20%', height: 'auto' }}
            alt="Logo"
          />

          <NavbarToggler onClick={toggleNavbar} className="me-2" />
          <Collapse isOpen={!collapsed} navbar>
            <Nav navbar>
            <Link
                  to="/vaga"
                  className={style.optionNav2}
                  style={{ marginTop: "1rem", marginBottom: "1rem" }}
                >
                  Página de Vagas
                </Link>
              <NavItem>
                <Link
                  to="/configEmpresa"
                  className={style.optionNav2}
                  style={{ marginTop: "1rem", marginBottom: "1rem" }}
                >
                  Minha Empresa
                </Link>
              </NavItem>
              <NavItem>
                <NavLink href="/">
                  Sair
                </NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
      ) : (
        <>
          <img
            src={logo}
            style={{ maxWidth: '100%', height: 'auto' }}
            className={style.headerNavbar}
            alt="Logo"
          />
              <ProfileInfo userInfo={userInfo} />
              <hr style={{ height: '2px', backgroundColor: '#737373', border: 'none' }} />

                  <div className={`${style.otherOptions}`}>
                      
                      <div className={style.optionGroup}>

                        <div className={style.optionNav}>
                          <Link to="/vaga" className={`${style.optionNav2}`} style={{ display: 'flex', alignItems: 'center', textDecoration: 'none' }}>
                            <HomeSvg width={22} height={22} />
                            <span className={style.optionNav2} style={{ marginLeft: '10px' }}>Página de Vagas</span>
                          </Link>
                        </div>

                        <div className={style.optionNav}>
                          <Link to="/configEmpresa" className={`${style.optionNav2}`} style={{ display: 'flex', alignItems: 'center', textDecoration: 'none' }}>
                            <ProfileSvg width={22} height={22} />
                            <span className={style.optionNav2} style={{ marginLeft: '10px' }}>Minha Empresa</span>
                          </Link>
                        </div>

                        <div className={style.optionNav}>
                          <Link to="/" className={`${style.optionNav2}`} style={{ display: 'flex', alignItems: 'center', textDecoration: 'none' }}>
                            <LeaveSvg width={22} height={22} />
                            <span className={style.optionNav2} style={{ marginLeft: '10px' }}>Sair</span>
                          </Link>
                        </div>

                    </div>
                  </div>
                  <hr style={{ height: '2px', backgroundColor: '#737373', border: 'none' }} />

        </>
      )}
    </div>
  );
};

export default VerticalNavbar;