import React, { useContext, useEffect,useState } from 'react';
import UserContext from '../../UserContext.js';
import VerticalNavbar from "../../components/verticalHavbar/VeticalNavbar2";
import UserConfig from "../../components/estudante/plataforma/userConfig";
import { useNavigate } from 'react-router-dom';

function PlataformaEstudante() {
    const navigate = useNavigate();

    const userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
    const [isDadosPreenchidos, setDadosPreenchidos] = useState(false);

    const DadosPreenchidoCheck = (dados) => {
        setDadosPreenchidos(dados);
    };

    useEffect(() => {
        if (!userInfo) {
            navigate('/');
        }
    }, [userInfo, navigate]);

    if (!userInfo) return <div>

    </div>;
    
    return (      
        <div>
            <VerticalNavbar userInfo={userInfo} DadosPreenchidos={isDadosPreenchidos} />
            <UserConfig userInfo={userInfo} DadosPreenchidosChecker={DadosPreenchidoCheck} />
        </div>
    );
}

export default PlataformaEstudante;
