import style from './profileInfo.module.css';
import { useNavigate } from 'react-router-dom';
import React, { useEffect } from 'react';

const ProfileInfo = ({ userInfo }) => {
    const navigate = useNavigate();

    // Verifica se userInfo é null e navega para a página inicial
    useEffect(() => {
        if (userInfo === null) {
            navigate('/');
        }
    }, [userInfo, navigate]); // Adiciona dependências

    return (
        <div className={style.profileGroup}>
            {userInfo && (
                <p className={`${style.info} ${style.infoName}`}>
                    {userInfo.Nome_Empresa}
                </p>
            )}
        </div>
    );
}

export default ProfileInfo;
