import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Checkmark } from 'react-checkmark'
import { Label, Button, Modal, Form, FormGroup, ModalBody, ModalHeader, Input } from 'reactstrap';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { FaEye, FaEyeSlash } from 'react-icons/fa'; 
import TermsModal from './TermosCoditionsEstudante.jsx';

import './formStyle.css'

const LoginForm = ({ isRegisterModalOpen, toggleLoginModal, onRegisterSuccess }) => {
  
  const normalizeText = (text) => {
    return new Intl.Collator(undefined, { sensitivity: 'base', ignorePunctuation: true }).compare(text, '');
  };

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const [modal_C, setModal_C] = useState(false);
  const toggle_C = () => setModal_C(!modal_C);

  useEffect(() => {

    setTimeout(() => {
      setModal(isRegisterModalOpen);
    }, 350);
  }, [isRegisterModalOpen]);

  const handleRegister = () => {
    toggle();
    onRegisterSuccess(); // Isso abrirá o modal de confirmação no LoginComponent
  };

  const [inputData, setInputData] = useState({ nome: '', email: '', c_email: '', senha: '', c_senha: '', curso: 188,idCurso: 188,idInstituicao_Ensino:3118, universidade: '-' });
  const [selectedCurso, setSelectedCurso] = useState(null);
  const [selectedUniversidade, setSelectedUniversidade] = useState(null); // Novo estado para a universidade
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [cursos, setCursos] = useState([]);
  const [cursosMedio, setCursosMedio] = useState([]);
  const [cursosTecnico, setCursosTecnico] = useState([]);
  const [universidade, setUniversidade] = useState([]);
  const [isTermosAceitos, seTermosAceitos] = useState(false);
  const [isEmailInvalid, setIsEmailInvalid] = useState(false);
  const [isPasswordInvalid, setIsPasswordInvalid] = useState(false);
  const [isContaExiste, setContaExiste] = useState(false);
  const [isPasswordVisible, setPasswordVisible] = useState(false);
  const [isCPasswordVisible, setCPasswordVisible] = useState(false);

  const [Nivel_Estudante, setNivel_Estudante] = useState('');

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputData({ ...inputData, [name]: value });
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleCursoSelect = (curso) => {
    setSelectedCurso(curso);
    toggleDropdown();
    setInputData((prevData) => ({
      ...prevData,
      curso: curso ? curso.Nome_Curso : '',
      idCurso: curso ? curso.idCurso : null,
    }));
  };

  const handleUniversidadeSelect = (universidade) => {
    setSelectedUniversidade(universidade);
    setInputData((prevData) => ({
      ...prevData,
      universidade: universidade ? universidade.Nome_Instituicao : '',
      idInstituicao_Ensino: universidade ? universidade.idInstituicao_Ensino : null,
    }));
  };

  function validatePassword(password, confirmPassword) {
    const hasMinLength = password.length >= 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumbers = /[0-9]/.test(password);
    const isMatch = password === confirmPassword;
  
    return hasMinLength && hasUpperCase && hasLowerCase && hasNumbers && isMatch;
  }

  function validateEmails(email, confirmEmail) {
    return email === confirmEmail;
  }

  const handleTermsAccepted = (accepted) => {
    seTermosAceitos(accepted);
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(prev => !prev);
  };

  const toggleCPasswordVisibility = () => {
    setCPasswordVisible(prev => !prev);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    var Erro = 0;
    setContaExiste(false)

    if (validatePassword(inputData['senha'], inputData['c_senha']))  {

      setIsPasswordInvalid(false)
    } else {
      setIsPasswordInvalid(true)
      Erro += 1;
    }

    if (validateEmails(inputData['email'], inputData['c_email'])) {
      setIsEmailInvalid(false);
    } else {
      setIsEmailInvalid(true);
      Erro += 1;
    }


    if (Erro != 0 || isTermosAceitos ){

      return;

    }

    axios.post('https://linkestagio.com.br/App.php?operation=check-data-cadastro-estudante', { ...inputData }, {
      headers: { 'Content-Type': 'application/json' },
    })
    .then((response) => {

      if (response.data == "Usuário já existe."){
        setContaExiste(true)
        return;
      }
    })
      .catch((error) => {
        console.error('Erro:', error);
        return;
      });

      setContaExiste(false)
      toggle();

      

    axios.post('https://linkestagio.com.br/App.php?operation=add-data-cadastro-estudante1', { ...inputData }, {
      headers: { 'Content-Type': 'application/json' }
    })
      .then(response => {
        if (response.data){

          setTimeout(() => {
            toggle_C(true);
          }, 450);

          axios.post('https://linkestagio.com.br/App.php?operation=add-data-cadastro-estudante2', { ...inputData }, {
            headers: { 'Content-Type': 'application/json' },
          })
        }
      })
  };

  useEffect(() => {
    axios.get('https://linkestagio.com.br/App.php?operation=get-cursos')
      .then(response => {
        if (Array.isArray(response.data)) {
          const cursosFaculdade = [];
          const cursosTecnicoTemp = [];
          const cursosMedioTemp = [];

          response.data.forEach(curso => {
            switch (curso.Tipo_Curso) {
              case 'Faculdade':
                cursosFaculdade.push(curso);
                break;
              case 'Técnico':
                cursosTecnicoTemp.push(curso);
                break;
              case 'Ensino Médio':
                cursosMedioTemp.push(curso);
                break;
              default:
                // Cursos que não se encaixam nas categorias conhecidas
                break;
            }
          });

          cursosFaculdade.sort((a, b) => a.Nome_Curso.localeCompare(b.Nome_Curso));
          cursosTecnicoTemp.sort((a, b) => a.Nome_Curso.localeCompare(b.Nome_Curso));
          cursosMedioTemp.sort((a, b) => a.Nome_Curso.localeCompare(b.Nome_Curso));

          // Atualiza os estados com os cursos filtrados por tipo
          setCursos(cursosFaculdade);
          setCursosTecnico(cursosTecnicoTemp);
          setCursosMedio(cursosMedioTemp);

        }
      })
      .catch(error => {
        console.error('Erro:', error);
      });
  }, []);


  useEffect(() => {
    axios.get('https://linkestagio.com.br/App.php?operation=get-universidade')
      .then(response => {
        if (Array.isArray(response.data)) {
          const filteredData = response.data.filter(universidade => universidade.Nome_Instituicao !== '-');
          
          filteredData.sort((a, b) => a.Nome_Instituicao.localeCompare(b.Nome_Instituicao));


          setUniversidade(filteredData);
        }
      })
      .catch(error => {
        console.error('Erro:', error);
      });
  }, []);

  return (
    <div>
        <div className="button-container">
          <Button className="access-button" onClick={toggle}>
            <span className="access-sub-text">Cadastrar</span>
          </Button>
        </div>      
        <Modal isOpen={modal} toggle={toggle}  className="modal-form">
        <ModalHeader style={{ paddingBottom: 0 , borderBottom: 'none' }} toggle={toggle}>
        <h1 className="form-title">Realize seu Cadastro</h1>

        </ModalHeader>

        <ModalBody>
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <p className="form-description"  style={{ marginBottom: 0 , marginTop: 0 }}>Preencha os campos abaixo para criar sua conta.</p>


              <TextField
                id="nome"
                name="nome"
                label="Nome Completo"
                placeholder=""
                type="text"
                className='linkInput'
                onChange={handleChange}
                size="small"
                style={{ marginBottom: '5px',marginTop: '5px' }}
                InputLabelProps={{
                  required: false // Remove o asterisco do label
                }}
                required
              />

            {/* <Label  style={{marginTop:'10px', fontSize: '18px', color: '#7e2553' }} for="Nivel_Esudante">Nivel de Escolaridade</Label>*/}
            <FormGroup className="radioGroup">
              <FormGroup required check>
                  <Label style={{marginTop: '10px', fontWeight: '600', color: 'gray'}} check>
                      <Input type="radio" name="Nivel_Esudante" value="Ensino Médio" onChange={() => {
                        setNivel_Estudante('Médio');
                      }} checked={Nivel_Estudante === 'Médio'} />{' '}
                      Ensino Médio
                  </Label>
              </FormGroup>
              <FormGroup check>
                  <Label style={{marginTop: '10px', fontWeight: '600', color: 'gray'}} check>
                      <Input type="radio" name="Nivel_Esudante" value="Ensino Técnico"  onChange={() => {
                        setNivel_Estudante('Técnico');
                        setSelectedCurso(null);

                      }} checked={Nivel_Estudante === 'Técnico'} />{' '}
                      Ensino Técnico
                  </Label>
              </FormGroup>
              <FormGroup check>
                  <Label style={{marginTop: '10px', fontWeight: '600', color: 'gray'}} check>
                      <Input type="radio" name="Nivel_Esudante" value="Graduação" onChange={() => {

                        setNivel_Estudante('Faculdade');
                        setSelectedCurso(null);

                      }} checked={Nivel_Estudante === 'Faculdade'} />{' '}
                      Ensino Superior
                  </Label>
              </FormGroup>
              </FormGroup>

              {Nivel_Estudante === 'Faculdade' && (
                <>
                <p className="form-description"  style={{ marginBottom: 0 , marginTop: 0 }}>Digite o nome do curso e da universidade que você está matriculado</p>
                <Autocomplete
                  disablePortal
                  id="curso"
                  name="curso"
                  noOptionsText="Nenhuma opção encontrada"
                  options={cursos}
                  getOptionLabel={(option) => option.Nome_Curso} 
                  getOptionSelected={(option, value) => option.idCurso === value.idCurso} 
                  value={selectedCurso} 
                  onChange={(event, value) => handleCursoSelect(value)} 
                  renderInput={(params) => <TextField {...params} label="Curso" />}
                  size="small"
                  style={{ marginBottom: '5px',marginTop: '5px' }}
                  required
                  filterOptions={(options, state) => {
                    const filteredOptions = options.filter((option) => {
                      const normalizeText = (text) => {
                        return text.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
                      };

                      return normalizeText(option.Nome_Curso).includes(normalizeText(state.inputValue));
                    });
            
                    return filteredOptions.slice(0, 8);
                  }}

                />


                <Autocomplete
                  disablePortal
                  id="universidade"
                  name="universidade"
                  noOptionsText="Nenhuma opção encontrada"
                  options={universidade}
                  getOptionLabel={(option) => option.Nome_Instituicao}
                  getOptionSelected={(option, value) => option.idInstituicao_Ensino === value.idInstituicao_Ensino}
                  value={selectedUniversidade}
                  onChange={(event, value) => handleUniversidadeSelect(value)}
                  renderInput={(params) => <TextField {...params} label="Universidade" />}
                  size="small"
                  style={{ marginBottom: '5px', marginTop: '8px' }}
                  required
                  filterOptions={(options, state) => {
                    const normalizeText = (text) => {
                      return text.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
                    };


                    const filteredOptions = options.filter((option) => {
                      return normalizeText(option.Nome_Instituicao).includes(normalizeText(state.inputValue));
                    });
            
                    return filteredOptions.slice(0, 64);
                  }}
                />
                </>
                )}
              {Nivel_Estudante === 'Técnico' && (
                <>
                <p className="form-description"  style={{ marginBottom: 0 , marginTop: 0 }}>Digite o nome do curso que você está matriculado</p>
                <Autocomplete
                  disablePortal
                  id="curso"
                  name="curso"
                  noOptionsText="Nenhuma opção encontrada"
                  options={cursosTecnico}
                  getOptionLabel={(option) => option.Nome_Curso} 
                  getOptionSelected={(option, value) => option.idCurso === value.idCurso} 
                  value={selectedCurso} 
                  onChange={(event, value) => handleCursoSelect(value)} 
                  renderInput={(params) => <TextField {...params} label="Curso" />}
                  size="small"
                  style={{ marginBottom: '5px',marginTop: '5px' }}
                  required
                  filterOptions={(options, state) => {
                    function normalizeText(text) {
                      const normalized = text.normalize("NFD");
                      const noDiacritics = normalized.replace(/[\u0300-\u036f]/g, "");
                      const lowerCased = noDiacritics.toLowerCase();
                      
                      return lowerCased;
                    }

                    const filteredOptions = options.filter((option) => {
                      return normalizeText(option.Nome_Curso).includes(normalizeText(state.inputValue));
                    });
            
                    return filteredOptions.slice(0, 64);
                  }}

                />
                </>
                )}
              
                <TextField
                  id="email"
                  name="email"
                  label="E-mail"
                  placeholder=""
                  type="email"
                  className='linkInput'
                  onChange={handleChange}
                  size="small"
                  style={{ marginBottom: '5px',marginTop: '5px' }}
                  required
                  InputLabelProps={{
                    required: false // Remove o asterisco do label
                  }}
                />
              
                <TextField
                  id="c_email"
                  name="c_email"
                  label="Confirmar E-mail"
                  placeholder=""
                  type="email"
                  className='linkInput'
                  onChange={handleChange}
                  size="small"
                  style={{ marginBottom: '5px',marginTop: '5px' }}
                  required
                  InputLabelProps={{
                    required: false // Remove o asterisco do label
                  }}
                />

                <p className="form-description" style={{ marginBottom: 0 , marginTop: 0 }}>Senha: mín. 8 caracteres, incluindo maiúsculas, minúsculas e números.</p>

                <div style={{ position: 'relative' }}>

                <TextField
                  id="senha"
                  name="senha"
                  placeholder=""
                  label="Senha"
                  type={isPasswordVisible ? 'text' : 'password'}                  
                  className='linkInput'
                  onChange={handleChange}
                  size="small"
                  style={{ marginBottom: '5px',marginTop: '5px' }}
                  required
                  InputLabelProps={{
                    required: false // Remove o asterisco do label
                  }}
                  
                />
                    <button
                    onClick={togglePasswordVisibility}
                    style={{
                      position: 'absolute',
                      right: '15px',
                      top: '50%',
                      transform: 'translateY(-50%)',
                      border: 'none',
                      background: 'transparent',
                      cursor: 'pointer'
                    }}
                    type="button"
                  >
                    {isPasswordVisible ? <FaEyeSlash /> : <FaEye />}
                  </button>
                  </div>

                <div style={{ position: 'relative' }}>

                <TextField
                  id="c_senha"
                  name="c_senha"
                  label="Confirmar Senha"
                  type={isCPasswordVisible ? 'text' : 'password'}                  
                  className='linkInput'
                  onChange={handleChange}
                  size="small"
                  style={{ marginBottom: '5px',marginTop: '5px' }}
                  required
                  InputLabelProps={{
                    required: false // Remove o asterisco do label
                  }}
                />
                  <button
                    onClick={toggleCPasswordVisibility}
                    style={{
                      position: 'absolute',
                      right: '15px',
                      top: '50%',
                      transform: 'translateY(-50%)',
                      border: 'none',
                      background: 'transparent',
                      cursor: 'pointer'
                    }}
                    type="button"
                  >
                    {isCPasswordVisible ? <FaEyeSlash /> : <FaEye />}
                  </button>
                </div>


              <TermsModal onTermsAccepted={handleTermsAccepted} />
              {isPasswordInvalid && (
                  <div style={{ color: 'red'}}>
                      Senhas diferentes ou invalidas.
                  </div>
              )}
              {isEmailInvalid && (
                  <div style={{ color: 'red'}}>
                      E-mails diferentes.
                  </div>
              )}
              <Button className="login-button" variant="custom" type="submit" toggle={toggle}>Realizar Cadastro</Button>

              <p className="sign-up-text">
                Já tem uma conta? <a href="#" onClick={handleRegister}>Entrar</a>
              </p>

            </FormGroup>
          </Form>
        </ModalBody>
      </Modal>
      
      <Modal isOpen={modal_C} toggle={toggle_C} className="modal-form">
        <ModalBody>
        <Checkmark size='64px' color='#2C7865' />
        <br></br>
        <h1 className="form-title">Cadastro realizado com sucesso!</h1>
        <p className="form-description">Em alguns instantes você receberá um e-mail para confirmar sua conta.</p>
        <Button className="login-button" variant="custom" onClick={toggle_C}>Voltar para a página inicial</Button>
        </ModalBody>
      </Modal>
    
    </div>
  );
}

export default LoginForm;
