import React, { useEffect , useContext} from 'react';

import { useSearchParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import UserContext from '../../UserContext.js';

import axios from 'axios'; // Certifique-se de ter o Axios instalado

function Verificador() {
    const navigate = useNavigate();
    let [searchParams] = useSearchParams();
    let email = searchParams.get("email");
    let v_code = searchParams.get("v_code");

useEffect(() => {
    let dadosVerificadores = { email, v_code };
    console.log(dadosVerificadores);
    axios.post('https://linkestagio.com.br/App.php?operation=verify-empresa', dadosVerificadores, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
    .then(response => {
        console.log(response.data)
        
    })
    .catch(error => {
        console.error('Erro na requisição:', error);
        alert('Erro na requisição.');
    });
}, [email, v_code]);

    return (
        navigate('/')
    );
}

export default Verificador;
