import React, { useEffect ,useState, useContext} from 'react';

import { useSearchParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import UserContext from '../../UserContext.js';

import axios from 'axios'; // Certifique-se de ter o Axios instalado

function Verificador() {
    const [executed, setExecuted] = useState(false);
    const navigate = useNavigate();

    let [searchParams] = useSearchParams();
    let user = searchParams.get("user");
    let vaga = searchParams.get("vaga");

    useEffect(() => {
        if (!executed) {
            let dadosVerificadores = { user, vaga };

            axios.post('https://linkestagio.com.br/App.php?operation=confirmar-presenca1', dadosVerificadores, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {
                window.location.href = 'https://linkestagio.com.br/?Entrevista=1';
                axios.post('https://linkestagio.com.br/App.php?operation=confirmar-presenca2', dadosVerificadores, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response2 => {

                    window.location.href = 'https://linkestagio.com.br/?Entrevista=1';
                })
            })
            .catch(error => {
                console.error('Erro na requisição:', error);
                alert('Erro na requisição.');
            });

            setExecuted(true);
        }else{
            window.location.href = 'https://linkestagio.com.br/?Entrevista=1';

        }
    }, [executed]);

}


export default Verificador;
