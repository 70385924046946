
import styles from './estudantePage.module.css';
import LoginComponent from '../../components/estudante/loginForm.jsx'
import RegisterComponent from '../../components/estudante/registerForm.jsx'
import Header from '../../components/Header/headerForm.jsx'
import Footer from '../../components/Footer/FooterForm.jsx';

import React, { useState,useEffect } from 'react';


const LoginForm = () => {

  
  const [isLoginModalOpen, setisLoginModalOpen] = useState(false);

  const toggleRegisterModal = () => {
    setisLoginModalOpen(!isLoginModalOpen);
  };

  const [isRegisterModalOpen, setisRegisterModalOpen] = useState(false);

  const toggleLoginModal = () => {
    setisRegisterModalOpen(!isRegisterModalOpen);
  };
  
  return (

    <>
    <Header />
    <div className={styles.largeDiv}></div> {/* Adicione a classe aqui */}
    <div className={styles.fullBackground}>

      <div className={styles.loginRegisterContainer}>
          <LoginComponent 
           isLoginModalOpen={isLoginModalOpen} 
           toggleRegisterModal={toggleRegisterModal} 
           onLoginSuccess={toggleLoginModal}
           />
          <RegisterComponent 
          isRegisterModalOpen={isRegisterModalOpen} 
          toggleLoginModal={toggleLoginModal} 
          onRegisterSuccess={toggleRegisterModal}/>
        </div>
      </div>
    <Footer />
    </>
  );
};

export default LoginForm;
