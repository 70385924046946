import style from "./plataforma.module.css";
//import '../formStyleRegisterLogin.module.css';

import axios from 'axios';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { FaEye, FaEyeSlash } from 'react-icons/fa'; 
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Checkmark } from 'react-checkmark'
import { Label, Form, FormGroup, Input, Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import InputMask from 'react-input-mask'; 
import { FaExclamationCircle } from 'react-icons/fa';

function UserConfig({ DadosPreenchidosChecker }) {
  const navigate = useNavigate();

  const [cursos, setCursos] = useState([]);
  const [cursosMedio, setCursosMedio] = useState([]);
  const [cursosTecnico, setCursosTecnico] = useState([]);
  const [universidade, setUniversidade] = useState([]);
  const [toggleState, setToggleState] = useState(1);
  const currentYear = new Date().getFullYear();
  const userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
  const [selectedFile, setSelectedFile] = useState(null);
  const [formData, setFormData] = useState({
    idUsuario: userInfo.idEstudantes,
    Email_Estudante: userInfo.Email_Estudante,
    Nome_Estudante: userInfo.Nome_Estudante,
    CPF_Estudante: userInfo.CPF_Estudante,
    Semestre_Estudante: userInfo.Semestre_Estudante,
    Horario_Aula_Estudante: userInfo.Horario_Aula_Estudante,
    Curso_idCurso: userInfo['curso'].Nome_Curso,
    Estudantes_Instituicao_Ensino_idInstituicao_Ensino: userInfo['instituicao_ensino'].Nome_Instituicao,
    Ano_Formatura: userInfo.Ano_Formatura,
    Idade_Estudante: userInfo.Idade_Estudante,
    CEP_Estudante: userInfo.CEP_Estudante,
    Sexo: userInfo.Sexo,
    Horario_Aula_Estudante: userInfo.Horario_Aula_Estudante,
    Deficiencia: userInfo.Deficiencia,
    Curriculo: userInfo.Curriculo,
    curso: userInfo.curso,
    idEstudantes: userInfo.idEstudantes,
    instituicao_ensino: userInfo.instituicao_ensino,
    Telefone_Estudante: userInfo.Telefone_Estudante
    });

    const [errors, setErrors] = useState({
      Nome_Estudante: false,
      CPF_Estudante: false,
      Email_Estudante: false,
      Idade_Estudante: false,
      Sexo: false,
      Telefone_Estudante: false,
      CEP_Estudante: false,
      Deficiencia: false,
      Semestre_Estudante: false,
      Ano_Formatura: false,
      Curriculo: false
    });

    const [modal_C2, setModal_C2] = useState(false);
    //const toggle_C2 = () => setModal_C2(!modal_C2);
  
    const toggle_C2 = () => {
      setModal_C2(!modal_C2);
      setToggleState(1);
    };

    const [modal_C3, setModal_C3] = useState(false);
    //const toggle_C2 = () => setModal_C2(!modal_C2);
  
    const toggle_C3 = () => {
      setModal_C3(!modal_C3);
      if (modal_C3) {
        window.location.reload();
      }
    };

    const [isPasswordInvalid, setIsPasswordInvalid] = useState(false);
    const [isPasswordVisible, setPasswordVisible] = useState(false);
    const [isPasswordVisible1, setPasswordVisible1] = useState(false);
    const [isPasswordVisible2, setPasswordVisible2] = useState(false);
    const [isPasswordVisible3, setPasswordVisible3] = useState(false);
    const [isConfirmPasswordVisible,setConfirmPasswordVisible] = useState(false);
    const [isDadosInvalid, setDadosInvalid] = useState(false);
    const [isCpfInvalid, setIsCpfInvalid] = useState(false);
    const [existingFilePath, setExistingFilePath] = useState(formData.Curriculo);
    const [modal, setModal] = useState(false);
    const toggleModal = () => setModal(!modal);
    
    const [Senhas, setSenhas] = useState({
      Senha_atual: '',
      Senha_nova: '',
      Senha_c_nova: ''
    });

    const [Senhas_Del, setSenhas_Del] = useState({
      Senha_Del: '',
      Senha_Del2: ''
      });

    const togglePasswordVisibility = () => {
      setPasswordVisible(prev => !prev);
    };

    const togglePasswordVisibility1 = () => {
      setPasswordVisible1(prev => !prev);
    };
  
    const togglePasswordVisibility2 = () => {
      setPasswordVisible2(prev => !prev);
    };
  
    const togglePasswordVisibility3 = () => {
      setPasswordVisible3(prev => !prev);
    }

    const toggleConfirmPasswordVisibility = () => {
      setConfirmPasswordVisible(prev => !prev);
    };


    function validaTelefone(telefone) {
      telefone = telefone.trim();
      const regex = /^\(\d{2}\) \d{8,9}$/;
      return regex.test(telefone);
  }

    const DeleteCurriculo = (event) => {
      event.preventDefault();
  
      axios.post('https://linkestagio.com.br/App.php?operation=usuario-excluir-curriculo', {Email:userInfo.Email_Estudante})
      .then((response) => {
          let updatedUserInfo = JSON.parse(sessionStorage.getItem('userInfo'));
  
          // Certifique-se de usar a mesma chave para todas as referências
          updatedUserInfo.Curriculo = null;  // Altera Curriculo para null
          sessionStorage.setItem('userInfo', JSON.stringify(updatedUserInfo));  // Salva no sessionStorage
          
          
          // Atualiza o estado do componente para refletir a mudança
          setFormData(prevState => ({ ...prevState, Curriculo: null }));
          setExistingFilePath('');
          setSelectedFile('');
          
          // Recarrega a página (se ainda necessário)
          window.location.reload();
  
      })
      .catch((error) => {
          console.error('Erro de login:', error);
      });
  }

    function validatePassword(password, confirmPassword) {
      const hasMinLength = password.length >= 8;
      const hasUpperCase = /[A-Z]/.test(password);
      const hasLowerCase = /[a-z]/.test(password);
      const hasNumbers = /[0-9]/.test(password);
      const isMatch = password === confirmPassword;
  
      return hasMinLength && hasUpperCase && hasLowerCase && hasNumbers && isMatch;
    }

    const [modal_C, setModal_C] = useState(false);
    const toggle_C = () => setModal_C(!modal_C);

    const Alterar_senha = (event) => {
      event.preventDefault();
      var Erro = 0;
  
      if (validatePassword(Senhas['Senha_nova'], Senhas['Senha_c_nova']))  {
        setIsPasswordInvalid(false);
      } else {
        setIsPasswordInvalid(true);
        Erro += 1;
      }    
  
      if (Erro != 0){
        return;
  
      }else{
  
        const combinedData = {
          ...Senhas,
          Email_Estudante: formData['Email_Estudante']
        };
        
        axios.post('https://linkestagio.com.br/App.php?operation=editar-senha-estudante', combinedData)
          .then((response) => {

            if(response.data == 'Senha Validada'){
              toggle_C();
              setIsPasswordInvalid(false);

            }else{
              setIsPasswordInvalid(true);
            }

          })
          .catch((error) => {
            console.error('Erro de login:', error);
          });
  
      }
  
    }

    const Apagar_Conta = (event) => {
      event.preventDefault();
      var Erro = 0;
  
  
      if (validatePassword(Senhas_Del['Senha_Del'], Senhas_Del['Senha_Del2']))  {
        setIsPasswordInvalid(false);
      } else {
        setIsPasswordInvalid(true);
        Erro += 1;
      }    
      
      if (Erro != 0){
        return;
  
      }else{
  
        const combinedData = {
          ...Senhas_Del,
          Email_Estudante: formData['Email_Estudante']
        };
        axios.post('https://linkestagio.com.br/App.php?operation=excluir-estudante', combinedData)
          .then((response) => {
            if (response.data != 'Senha Errada'){
              navigate('/'); 
            }

          })
          .catch((error) => {
            console.error('Erro de login:', error);
          });
  
      }
  
    }

    function validaCPF(cpf) {
      cpf = (cpf ?? '').replace(/[^\d]+/g, ''); // Remove caracteres não numéricos
      if (cpf.length !== 11 || /^(\d)\1{10}$/.test(cpf)) return false; // Verifica o tamanho e sequências iguais
  
      let soma = 0;
      for (let i = 0; i < 9; i++) soma += parseInt(cpf.charAt(i)) * (10 - i);
      let resto = 11 - (soma % 11);
      if (resto === 10 || resto === 11) resto = 0;
      if (resto !== parseInt(cpf.charAt(9))) return false;
  
      soma = 0;
      for (let i = 0; i < 10; i++) soma += parseInt(cpf.charAt(i)) * (11 - i);
      resto = 11 - (soma % 11);
      if (resto === 10 || resto === 11) resto = 0;
      if (resto !== parseInt(cpf.charAt(10))) return false;
  
      return true;
    }

  const toggleTab = (index) => {
    setToggleState(index);
  };

  const handleFileChange = (event) => {
    if (event.target.files[0]) {
        setSelectedFile(event.target.files[0]);
    } else {
        setSelectedFile(null);
    }
    };

    const [selectedUniversidade, setSelectedUniversidade] = useState(null); // Novo estado para a universidade
    const handleUniversidadeSelect = (universidade) => {
      setSelectedUniversidade(universidade);
      setFormData((prevData) => ({
        ...prevData,
        universidade: universidade ? universidade.Nome_Instituicao : '',
        idInstituicao_Ensino: universidade ? universidade.idInstituicao_Ensino : null,
      }));
    };

    const [modal_S, setModal_S] = useState(false);
    const toggle_S =(event) => {
      event.preventDefault();
  
      setModal_S(!modal_S);
    }

    const ProximaPagina = (event) => {
      event.preventDefault();
      if (toggleState === 1){
        setToggleState((2));
      }else if (toggleState === 2){
        setToggleState((3));
      }
    }


    const handleSaveFile = (event) => {
        event.preventDefault();
        var Erro = 0;

        var requiredFields = [
          'Email_Estudante',
          'Nome_Estudante',
          'Horario_Aula_Estudante',
          'Curso_idCurso',
          'Estudantes_Instituicao_Ensino_idInstituicao_Ensino',
          'Ano_Formatura',
          'Idade_Estudante',
          'CEP_Estudante',
          'Sexo',
          'Deficiencia',
          'Semestre_Estudante'
          ];  

        if (userInfo['curso']['Nome_Curso'] == "Ensino Médio"){
          var requiredFields = [
            'Email_Estudante',
            'Nome_Estudante',
            'Horario_Aula_Estudante',
            'Curso_idCurso',
            'Estudantes_Instituicao_Ensino_idInstituicao_Ensino',
            'Ano_Formatura',
            'Idade_Estudante',
            'CEP_Estudante',
            'Sexo',
            'Deficiencia'
            ];  

        }


        const invalidFields = requiredFields.filter(field => !formData[field] || formData[field].trim() === '');
        const isEveryFieldValid = invalidFields.length === 0;


        invalidFields.forEach(field => {
          errors[field] = true;
        });


        if (!validaTelefone(formData['Telefone_Estudante'])) {
          errors['Telefone_Estudante'] = true;
          //setDadosInvalid(true);
          Erro += 1;

        } else {
          errors['Telefone_Estudante'] = false;
        }

        if (!validaCPF(formData['CPF_Estudante'])) {
          errors['CPF_Estudante'] = true;
          Erro += 1;

        } else {
          errors['CPF_Estudante'] = false;
        }

        const validFields = requiredFields.filter(field => formData[field] && formData[field].trim() !== '');

        validFields.forEach(field => {
            errors[field] = false;
        });


        if (!isEveryFieldValid) {
          Erro += 1;


        }

        if (Erro != 0){
          toggle_C2();
          return;
        }

        const uploadFormData = new FormData();
        uploadFormData.append("Id", formData.idUsuario);
        uploadFormData.append("arquivoPdf", selectedFile);


          const config = {
            headers: {
                'Content-Type': 'multipart/form-data' // Correto, necessário para o envio de arquivos
            }
          };


          axios.post('https://linkestagio.com.br/App.php?operation=enviar-curriculo', uploadFormData, config)
          .then(function (response) {



            if (response.data !== "Nenhum arquivo enviado." && existingFilePath === null) {

              var path_curriculo = response.data;
              formData.Curriculo = path_curriculo;
              const combinedData = {
                ...formData,
                ...Senhas,
              };

                toggle_C3();

                axios.post('https://linkestagio.com.br/App.php?operation=usuario-atualizar-dados', combinedData)
                .then((response) => {
                    var path_curriculo = formData.Curriculo;
                    formData.Curriculo = path_curriculo;
                    formData.Valor = path_curriculo;
        
                    sessionStorage.removeItem('userInfo');
                    sessionStorage.setItem('userInfo', JSON.stringify(formData)); 
        
                  })
                  .catch((error) => {
                    console.error('Erro de login:', error);
                  });


              }if (response.data === "Nenhum arquivo enviado." && existingFilePath === null) {
                toggleModal();
              }else{


                if(response.data !== "Nenhum arquivo enviado."){

                  var path_curriculo = response.data;
                  formData.Curriculo = path_curriculo;

                }

                const combinedData = {
                  ...formData,
                  ...Senhas,
                };

                toggle_C3();
                axios.post('https://linkestagio.com.br/App.php?operation=usuario-atualizar-dados', combinedData)
                .then((response) => {


                    //var path_curriculo = formData.Curriculo;
                    //formData.Curriculo = path_curriculo;
                    //formData.Valor = path_curriculo;
        
                    sessionStorage.removeItem('userInfo');
                    sessionStorage.setItem('userInfo', JSON.stringify(formData)); 
        
                  })
                  .catch((error) => {
                    console.error('Erro de login:', error);
                  });


                //formData.Curriculo = response.data; // Atualiza o caminho do currículo no formData
                //sessionStorage.removeItem('userInfo');
                
                //sessionStorage.setItem('userInfo', JSON.stringify(formData)); // Atualiza os dados do usuário no sessionStorage
                
                //setSelectedFile(response.data);

                //setExistingFilePath(null)
                //setExistingFilePath(formData.Curriculo);
              }
              //window.location.reload();
        })
        .catch(function (error) {
        });
        
      };

    useEffect(() => {

      if (userInfo.Curriculo !== ""  && userInfo.Curriculo !== null) {
        DadosPreenchidosChecker(true);
        setExistingFilePath(userInfo.Curriculo);
      }else{
      }
    }, [userInfo.Curriculo]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });

  };

  const handleSenhaChange = (event) => {
    const { name, value } = event.target;
    setSenhas({ ...Senhas, [name]: value });
  };

  useEffect(() => {
    axios.get('https://linkestagio.com.br/App.php?operation=get-cursos')
    .then(response => {
      if (Array.isArray(response.data)) {
        const cursosFaculdade = [];
        const cursosTecnicoTemp = [];
        const cursosMedioTemp = [];

        response.data.forEach(curso => {
          switch (curso.Tipo_Curso) {
            case 'Faculdade':
              cursosFaculdade.push(curso);
              break;
            case 'Técnico':
              cursosTecnicoTemp.push(curso);
              break;
            case 'Ensino Médio':
              cursosMedioTemp.push(curso);
              break;
            default:
              // Cursos que não se encaixam nas categorias conhecidas
              break;
          }
        });

        // Atualiza os estados com os cursos filtrados por tipo
        setCursos(cursosFaculdade);
        setCursosTecnico(cursosTecnicoTemp);
        setCursosMedio(cursosMedioTemp);

      }
    })
      .catch(error => {
        console.error('Erro:', error);
      });
  }, []);


  useEffect(() => {
    axios.get('https://linkestagio.com.br/App.php?operation=get-universidade')
      .then(response => {
        if (Array.isArray(response.data)) {
          setUniversidade(response.data);
        }
      })
      .catch(error => {
        console.error('Erro:', error);
      });
  }, []);


  return (
    <>

        <Modal isOpen={modal_S} toggle={toggle_S} className="modal-form">
          <ModalHeader style={{ borderBottom: 'none' }} toggle={toggle_S}>
          <h1 className="form-title">Excluir sua conta</h1>
          <p className="form-description" style={{ marginBottom: 0 , marginTop: 0 }}>Para excluir sua conta digite e confirme a sua senha atual.</p>
          </ModalHeader>
          <ModalBody>

          <Label for="email" className="linkLabel">Senha</Label>
          <div className="input-block">
            <Input
              name="senha"
              id="senha"
              placeholder="Senha"
              type={isPasswordVisible ? 'text' : 'password'}
              value={Senhas_Del.Senha_Del  || ''}
              onChange={(e) => setSenhas_Del({ ...Senhas_Del, Senha_Del: e.target.value })}
              required
            />
                        <button
              onClick={togglePasswordVisibility}
              style={{
                position: 'absolute',
                right: '25px',
                top: '27%',
                transform: 'translateY(-50%)',
                border: 'none',
                background: 'transparent',
                cursor: 'pointer'
              }}
              type="button"
            >
              {isPasswordVisible ? <FaEyeSlash /> : <FaEye />}
            </button>

          </div>
          <Label for="email" className="linkLabel">Confirmar Senha</Label>
          <div className="input-block">
            <Input
              name="senha"
              id="senha"
              placeholder="Senha"
              type={isConfirmPasswordVisible ? 'text' : 'password'}
              value={Senhas_Del.Senha_Del2  || ''}
              onChange={(e) => setSenhas_Del({ ...Senhas_Del, Senha_Del2: e.target.value })}
              required
            />
            <button
              onClick={toggleConfirmPasswordVisibility}
              style={{
                position: 'absolute',
                right: '25px',
                top: '60%',
                transform: 'translateY(-50%)',
                border: 'none',
                background: 'transparent',
                cursor: 'pointer'
              }}
              type="button"
            >
              {isConfirmPasswordVisible ? <FaEyeSlash /> : <FaEye />}
            </button>
          </div>
          <p className="form-description" style={{ marginBottom: 0 , marginTop: 0 }}><strong>Atenção:</strong> Essa ação não pode ser desfeita.</p>
          <Button className="login-button" onClick={Apagar_Conta} variant="" >Excluir a Conta</Button>

          </ModalBody>

        </Modal>


      <Modal isOpen={modal_C} toggle={toggle_C} onHide={toggle_C} centered>
        <ModalHeader style={{ borderBottom: 'none' }} toggle={toggle_C}>

        </ModalHeader>
          <Checkmark size='64px' color='#2C7865' />

          <ModalBody>
          <br></br>
          <h1 className="form-title">Senha alterada com sucesso!</h1>
          <p className="form-description">Sua senha foi alterada com sucesso, utilize ela para realizar o login na plataforma Link Estágio.</p>
          <Button className="login-button" variant="custom" onClick={toggle_C}>Voltar para a página de Estudante.</Button>
          </ModalBody>
      </Modal>

      <Modal isOpen={modal_C3} toggle={toggle_C3} onHide={toggle_C3} centered>
        <ModalHeader style={{ borderBottom: 'none' }} toggle={toggle_C3}>

        </ModalHeader>
          <Checkmark size='64px' color='#2C7865' />

          <ModalBody>
          <br></br>
          <h1 className="form-title">Dados alterados com sucesso!</h1>
          <p className="form-description">Seus dados cadastrais e curriculo foram atualizados com sucesso!</p>
          <Button className="login-button" variant="custom" onClick={toggle_C3}>Voltar para a página de Estudante.</Button>
          </ModalBody>
      </Modal>

      <Modal isOpen={modal_C2} toggle={toggle_C2} className="modal-form">
          <ModalHeader style={{ borderBottom: 'none' }} toggle={toggle_C2}>

          </ModalHeader>
            <div className="d-flex flex-column align-items-center">
              <FaExclamationCircle size={96} color="#d24e42" />
            </div>

            <ModalBody>
            <br></br>
            <h1 className="form-title">Corrija todos os campos antes de continuar</h1>

            <p className="form-description">Alguns campos estão incorretos, corrija-os antes de editar os dados do seu perfil.</p>
            <Button className="login-button" variant="custom" onClick={toggle_C2}>Voltar para a página do estudante</Button>
            </ModalBody>
        </Modal>
    <div className={`${style.configAccount}`}>
      <nav className={`${style.configAccountNav}`}>
      <p
        className={`${style.optionNav} ${
          toggleState === 1 ? `${style.activateTabs} ${style.tabs}` : style.tabs
        }`}
        onClick={() => toggleTab(1)}
      >
        Dados do Estudante
      </p>
      <p
        className={`${style.optionNav} ${
          toggleState === 2 ? `${style.activateTabs} ${style.tabs}` : style.tabs
        }`}
        onClick={() => toggleTab(2)}
      >
        Meu Curso
      </p>
      <p
        className={`${style.optionNav} ${
          toggleState === 3 ? `${style.activateTabs} ${style.tabs}` : style.tabs
        }`}
        onClick={() => toggleTab(3)}
      >
        Currículo
      </p>
      <p
        className={`${style.optionNav} ${
          toggleState === 4 ? `${style.activateTabs} ${style.tabs}` : style.tabs
        }`}
        onClick={() => toggleTab(4)}
      >
        Segurança
      </p>
      </nav>

      <Form className={`${style.bodyForm}`}>
        {toggleState === 1 && (
          <>
            <FormGroup>
              <Label style={{ fontSize: '20px', color: '#7e2553' }} for="Nome_Estudante">Nome Completo</Label>
              <Input invalid={errors.Nome_Estudante} required type="text" name="Nome_Estudante" id="Nome_Estudante" placeholder="Nome Completo" value={formData.Nome_Estudante} onChange={handleChange}/>
            </FormGroup>

            <div className="input-row">
              <div className="input-block half">

              <FormGroup>
                <Label style={{ fontSize: '20px', color: '#7e2553' }} for="Idade_Estudante">Idade</Label>
                <Input 
                invalid={errors.Idade_Estudante}
                required type="number" min="18" name="Idade_Estudante" id="Idade_Estudante" placeholder="Idade" value={formData.Idade_Estudante} onChange={handleChange}/>
              </FormGroup>
              </div>
              <div className="input-block half">

                <FormGroup>
                  <Label style={{ fontSize: '20px', color: '#7e2553' }} for="Sexo">Sexo</Label>
                  <Input required
                  invalid={errors.Sexo}
                  type="select" name="Sexo" id="Sexo" value={formData.Sexo} onChange={handleChange}>
                    <option value="" disabled>Selecione</option>
                    <option value="Masculino">Masculino</option>
                    <option value="Feminino">Feminino</option>
                    <option value="Outro">Outro</option>
                    <option value="Prefiro não informar">Prefiro não informar</option>
                  </Input>
                </FormGroup>
              </div>
            </div>

            <div className="input-row">
              <div className="input-block half">

            <FormGroup>
              <Label style={{ fontSize: '20px', color: '#7e2553' }} for="CPF_Estudante">CPF</Label>
              <Input
                className={`form-control ${errors.CPF_Estudante ? 'is-invalid' : ''}`}
                name="CPF_Estudante"
                id="CPF_Estudante"
                placeholder="CPF"
                value={formData.CPF_Estudante}
                onChange={handleChange}
                
            >
                {(inputProps) => (
                    <input {...inputProps} required />
                )} 
                
            </Input>
            {isCpfInvalid && (
                <div style={{ color: 'red' }}>
                    CPF inválido.
                </div>
            )}
            
            </FormGroup>
            </div>
              <div className="input-block half">

              <FormGroup>
                <Label style={{ fontSize: '20px', color: '#7e2553' }} for="CEP_Estudante">CEP</Label>
                <InputMask
                  mask="99999-999"
                  value={formData.CEP_Estudante || ''}
                  onChange={handleChange}
                  className={`form-control ${errors.CEP_Estudante ? 'is-invalid' : ''}`}
                  id="CEP_Estudante"
                  name="CEP_Estudante"
                  placeholder="-"
                  type="text"
                  required
                >
                {(inputProps) => (
                <input {...inputProps} />
                )}
              </InputMask>
              </FormGroup>
              </div>
            </div>

            <div className="input-row">
              <div className="input-block half">

                <FormGroup>
                  <Label style={{ fontSize: '20px', color: '#7e2553' }} for="Email_Estudante">E-mail</Label>
                  <Input
                    required
                    type="email"
                    name="Email_Estudante"
                    id="Email_Estudante"
                    invalid={errors.Email_Estudante}
                    placeholder="Email para contato"
                    className={errors.Email_Estudante ? 'input-error' : ''}
                    value={formData.Email_Estudante}
                    onChange={handleChange}
                  />
                  {<div className="invalid-feedback">{errors.Email_Estudante}</div>}
                </FormGroup>

            </div>

              <div className="input-block half">

              <FormGroup>
              <Label style={{ fontSize: '20px', color: '#7e2553' }} for="Telefone_Estudante">Telefone para Contato</Label>
                    <InputMask
                      mask="(99) 999999999"
                      maskChar={null}
                      invalid={errors.Telefone_Estudante}
                      value={formData.Telefone_Estudante || ''}
                      onChange={handleChange}
                      className={`form-control ${errors.Telefone_Estudante ? 'is-invalid' : ''}`}
                      id="Telefone_Estudante"
                      name="Telefone_Estudante"
                      placeholder="Telefone para contato"
                      type="text"
                      required
                    > 
                    </InputMask>

              </FormGroup>

              </div>
            </div>


            <FormGroup>
              <Label style={{ fontSize: '20px', color: '#7e2553' }} for="Deficiencia">Possui Deficiência?</Label>
              <Input required type="select" invalid={errors.Deficiencia} name="Deficiencia" id="Deficiencia" value={formData.Deficiencia} onChange={handleChange}>
                <option value="" disabled>Selecione</option>
                <option value="Sim">Sim</option>
                <option value="Não">Não</option>
                <option value="Prefiro não informar">Prefiro não informar</option>
              </Input>
            </FormGroup>
            {isDadosInvalid && (
              <div style={{ color: 'red' }}>
                  Dados inválidos em alguma das abas, verifique,preencha e corrija todos os dados.
              </div>
            )}
            <button type="submit" onClick={ProximaPagina} class={style.Confirm} >Próxima Página</button>

          </>
        )}

        {toggleState === 2 && (
          <>


            {formData.curso.Tipo_Curso === 'Faculdade' && (
            <FormGroup>
              <Label  style={{ fontSize: '20px', color: '#7e2553' }} for="Curso_idCurso">Curso</Label>
              <Autocomplete
                  disablePortal
                  id="Curso_idCurso"
                  name="Curso_idCurso"
                  noOptionsText="Nenhuma opção encontrada"
                  options={cursos}
                  getOptionLabel={(option) => option.Nome_Curso} 
                  getOptionSelected={(option, value) => option.idCurso === value.idCurso} 
                  value={cursos.find(u => u.Nome_Curso === formData.curso.Nome_Curso) || null}
                  onChange={(event, newValue) => {

                    if (newValue) {
    
                      formData.Curso_idCurso = newValue.Nome_Curso
                      formData.curso.idCurso = newValue.idCurso
                      formData.curso.Nome_Curso = newValue.Nome_Curso
    
                      setFormData(prev => ({
                        ...prev,
                        Curso_idCurso: newValue ? newValue.Nome_Curso : ''
                      }));
                    }
                  }}

                  renderInput={(params) => <TextField {...params} />}
                  size="small"
                  style={{ marginBottom: '5px',marginTop: '5px' }}
                  required
                  filterOptions={(options, state) => {
                    const filteredOptions = options.filter((option) => {

                      const normalizeText = (text) => {
                        return text.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
                      };
                      return normalizeText(option.Nome_Curso).includes(state.inputValue);
                    });
            
                    return filteredOptions.slice(0, 12);
                  }}

                />
            
            </FormGroup>
            )}

            {formData.curso.Tipo_Curso === 'Técnico' && (
            <FormGroup>
              <Label  style={{ fontSize: '20px', color: '#7e2553' }} for="Curso_idCurso">Curso</Label>
              <Autocomplete
                  disablePortal
                  id="Curso_idCurso"
                  name="Curso_idCurso"
                  noOptionsText="Nenhuma opção encontrada"
                  options={cursosTecnico}
                  getOptionLabel={(option) => option.Nome_Curso} 
                  getOptionSelected={(option, value) => option.idCurso === value.idCurso} 
                  value={cursosTecnico.find(u => u.Nome_Curso === formData.curso.Nome_Curso) || null}
                  onChange={(event, newValue) => {

                    if (newValue) {
    
                      formData.Curso_idCurso = newValue.Nome_Curso
                      formData.curso.idCurso = newValue.idCurso
                      formData.curso.Nome_Curso = newValue.Nome_Curso
    
                      setFormData(prev => ({
                        ...prev,
                        Curso_idCurso: newValue ? newValue.Nome_Curso : ''
                      }));
                    }
                  }}

                  renderInput={(params) => <TextField {...params} />}
                  size="small"
                  style={{ marginBottom: '5px',marginTop: '5px' }}
                  required
                  filterOptions={(options, state) => {
                    const filteredOptions = options.filter((option) => {

                      const normalizeText = (text) => {
                        return text.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
                      };
                      return normalizeText(option.Nome_Curso).includes(state.inputValue);
                    });
            
                    return filteredOptions.slice(0, 12);
                  }}

                />
            
            </FormGroup>
            )}


            {formData.curso.Tipo_Curso === 'Faculdade' && (
            <>
            <FormGroup>
              <Label  style={{ fontSize: '20px', color: '#7e2553' }} for="Estudantes_Instituicao_Ensino_idInstituicao_Ensino">Universidade</Label>
              <Autocomplete
              disablePortal
              id="Estudantes_Instituicao_Ensino_idInstituicao_Ensino"
              name="Estudantes_Instituicao_Ensino_idInstituicao_Ensino"
              noOptionsText="Nenhuma opção encontrada"
              options={universidade}
              getOptionLabel={(option) => option.Nome_Instituicao || ''}
              value={universidade.find(u => u.idInstituicao_Ensino === formData.instituicao_ensino.idInstituicao_Ensino) || null}
              onChange={(event, newValue) => {

                if (newValue) {

                  formData.Estudantes_Instituicao_Ensino_idInstituicao_Ensino = newValue.Nome_Instituicao
                  formData.instituicao_ensino.idInstituicao_Ensino = newValue.idInstituicao_Ensino
                  formData.instituicao_ensino.Nome_Instituicao = newValue.Nome_Instituicao

                  setFormData(prev => ({
                    ...prev,
                    Estudantes_Instituicao_Ensino_idInstituicao_Ensino: newValue ? newValue.Nome_Instituicao : ''
                  }));

                }
              }}
              renderInput={(params) => <TextField {...params} required />}
              size="small"
              style={{ marginBottom: '5px', marginTop: '8px' }}
              filterOptions={(options, state) => {
                
                const filteredOptions = options.filter((option) => option.Nome_Instituicao.toLowerCase().includes(state.inputValue.toLowerCase()));
                return filteredOptions.slice(0, 64);
              }}
            />
            </FormGroup>
            </>
            )}
            
            <FormGroup>
            {formData.curso.Tipo_Curso === 'Faculdade' && (
              <>
                <Label style={{ fontSize: '20px', color: '#7e2553' }} for="Semestre_Estudante">Semestre</Label>
                <Input required type="select" 
                invalid={errors.Semestre_Estudante}
                style={{ maxHeight: '200px', overflowY: 'auto' }}
                name = "Semestre_Estudante" id="Semestre_Estudante" placeholder="" value={formData.Semestre_Estudante} onChange={handleChange}>
                  <option value="" disabled>Selecione</option>
                  {[...Array(10)].map((_, index) => (
                    <option key={index + 1} value={index + 1}>{`${index + 1}º semestre`}</option>
                  ))}
                </Input>
                </>
              )}  

              {formData.curso.Tipo_Curso === 'Técnico' && (
                <>
                <Label style={{ fontSize: '20px', color: '#7e2553' }} for="Semestre_Estudante">Semestre</Label>
                <Input required type="select" 
                invalid={errors.Semestre_Estudante}
                style={{ maxHeight: '200px', overflowY: 'auto' }}
                name = "Semestre_Estudante" id="Semestre_Estudante" placeholder="" value={formData.Semestre_Estudante} onChange={handleChange}>
                  <option value="" disabled>Selecione</option>
                  {[...Array(10)].map((_, index) => (
                    <option key={index + 1} value={index + 1}>{`${index + 1}º semestre`}</option>
                  ))}
                </Input>
                </>
              )}

              {formData.curso.Tipo_Curso === 'Médio' && (
                <>
                <Label style={{ fontSize: '20px', color: '#7e2553' }} for="Semestre_Estudante">Ano Cursado</Label>
                <Input required type="select" 
                invalid={errors.Semestre_Estudante}
                name = "Semestre_Estudante" id="Semestre_Estudante"
                placeholder="" value={formData.Semestre_Estudante} onChange={handleChange}>
                    <option value="" disabled>Selecione</option>
                  <option value="1">1º ano</option>
                  <option value="2">2º ano</option>
                  <option value="3">3º ano</option>
                </Input>
                </>
              )}

            </FormGroup>
            <FormGroup>
              <Label  style={{ fontSize: '20px', color: '#7e2553' }} for="Ano_Formatura">Ano de Formatura</Label>
              <Input required type="select"
              invalid={errors.Ano_Formatura}
              name = "Ano_Formatura" id="Ano_Formatura" placeholder="-" value={formData.Ano_Formatura} onChange={handleChange}>
              <option value="" disabled>Selecione</option>


              {[...Array(6)].map((_, index) => (
                <option key={index} value={currentYear + index}>{currentYear + index}</option>
              ))}
              </Input>
            </FormGroup>

            <Label  style={{ fontSize: '20px', color: '#7e2553' }} for="Horario_Aula">Horário Aula </Label>
            { errors.Ano_Formatura && <Label  style={{marginLeft:'10px', color: 'red' }} for="Horario_Aula"> Selecione um item:</Label> }

            <FormGroup invalid={errors.Horario_Aula} className="radioGroup">
              <FormGroup required check>
                  <Label style={{marginTop: '10px', fontWeight: '600', color: 'gray'}} check>
                      <Input type="radio" name="Horario_Aula_Estudante" value="M" onChange={handleChange} checked={formData.Horario_Aula_Estudante === 'M'} />{' '}
                      Manhã
                  </Label>
              </FormGroup>
              <FormGroup check>
                  <Label style={{marginTop: '10px', fontWeight: '600', color: 'gray'}} check>
                      <Input type="radio" name="Horario_Aula_Estudante" value="T" onChange={handleChange} checked={formData.Horario_Aula_Estudante === 'T'} />{' '}
                      Tarde
                  </Label>
              </FormGroup>
              <FormGroup check>
                  <Label style={{marginTop: '10px', fontWeight: '600', color: 'gray'}} check>
                      <Input type="radio" name="Horario_Aula_Estudante" value="N" onChange={handleChange} checked={formData.Horario_Aula_Estudante === 'N'} />{' '}
                      Noite
                  </Label>
              </FormGroup>
              <FormGroup check>
                  <Label style={{marginTop: '10px', fontWeight: '600', color: 'gray'}} check>
                      <Input type="radio" name="Horario_Aula_Estudante" value="I" onChange={handleChange} checked={formData.Horario_Aula_Estudante === 'I'} />{' '}
                      Integral
                  </Label>
              </FormGroup>
              <FormGroup check>
                  <Label style={{marginTop: '10px', fontWeight: '600', color: 'gray'}} check>
                      <Input type="radio" name="Horario_Aula_Estudante" value="E" onChange={handleChange} checked={formData.Horario_Aula_Estudante === 'E'} />{' '}
                      EAD
                  </Label>
              </FormGroup>
            </FormGroup>
            {isDadosInvalid && (
              <div style={{ color: 'red' }}>
                  Dados inválidos em alguma das abas, verifique,preencha e corrija todos os dados.
              </div>
            )}
            <button onClick={ProximaPagina} class={style.Confirm} >Próxima Página</button>


          </>
        )}

        {toggleState === 3 && (
          <>
            <FormGroup>
              <Label  style={{ fontSize: '20px', color: '#7e2553' }} for="Curriculo">Currículo</Label>
              <div class={style.container_separar}>
              <Input
                type="file"
                id="pdfUpload"
                name="pdfUpload"
                accept="application/pdf"
                onChange={handleFileChange}
              />
              <button onClick={handleSaveFile} class={style.Confirm} >Salvar</button>
              </div>
              {selectedFile ? (
                <iframe
                  src={URL.createObjectURL(selectedFile)}
                  title="PDF Preview"
                  width="100%"
                  height="500px"
                />
              ) : existingFilePath ? (
                <iframe
                  key={existingFilePath} // Adiciona uma chave baseada no caminho do arquivo
                  src={existingFilePath}
                  title="Existing PDF Preview"
                  width="100%"
                  height="500px"
                />
              ) : null}
              
    
            </FormGroup>
            {isDadosInvalid && (
              <div style={{ color: 'red' }}>
                  Dados inválidos em alguma das abas, verifique,preencha e corrija todos os dados.
              </div>
            )}
            <div class={style.container_separar2}>
              <button onClick={DeleteCurriculo} class={style.Delete} >Excluir Curriculo</button>
            </div>

          </>
        )}

{toggleState === 4 && (
            <>
              <FormGroup>
              <div className="input-block" style={{ position: 'relative' }}>

                <Label  style={{ fontSize: '20px', color: '#7e2553' }} for="Senhas">Senha Atual</Label>
                <Input                           
                type={isPasswordVisible1 ? 'text' : 'password'}
                name="Senha_atual" id="Senha_atual" placeholder="" 
                value={Senhas.Senha_atual  || ''} onChange={handleSenhaChange}/>
                <button
                    onClick={togglePasswordVisibility1}
                    style={{
                      position: 'absolute',
                      right: '15px',
                      top: '70%',
                      transform: 'translateY(-50%)',
                      border: 'none',
                      background: 'transparent',
                      cursor: 'pointer'
                    }}
                    type="button"
                  >
                    {isPasswordVisible1 ? <FaEyeSlash /> : <FaEye />}
                  </button>
              </div>
              </FormGroup>
              <FormGroup>
                <Label  style={{ fontSize: '20px', color: '#7e2553' }} for="Senha_nova">Nova Senha</Label>
                <div className="input-block" style={{ position: 'relative' }}>

                <Input type={isPasswordVisible2 ? 'text' : 'password'} name="Senha_nova" id="Senha_nova" placeholder="" 
                value={Senhas.Senha_nova  || ''} onChange={handleSenhaChange}/>
                <button
                    onClick={togglePasswordVisibility2}
                    style={{
                      position: 'absolute',
                      right: '15px',
                      top: '50%',
                      transform: 'translateY(-50%)',
                      border: 'none',
                      background: 'transparent',
                      cursor: 'pointer'
                    }}
                    type="button"
                  >
                    {isPasswordVisible2 ? <FaEyeSlash /> : <FaEye />}
                  </button>
                </div>
              
              </FormGroup>
              <FormGroup>
                <Label  style={{ fontSize: '20px', color: '#7e2553' }} for="Senha_c_nova">Confirmar senha</Label>
                <div className="input-block" style={{ position: 'relative' }}>

                <Input  type={isPasswordVisible3 ? 'text' : 'password'} name="Senha_c_nova" id="Senha_c_nova" placeholder="" 
                value={Senhas.Senha_c_nova  || ''} onChange={handleSenhaChange}/>
              
              
                <button
                    onClick={togglePasswordVisibility3}
                    style={{
                      position: 'absolute',
                      right: '15px',
                      top: '50%',
                      transform: 'translateY(-50%)',
                      border: 'none',
                      background: 'transparent',
                      cursor: 'pointer'
                    }}
                    type="button"
                  >
                    {isPasswordVisible3 ? <FaEyeSlash /> : <FaEye />}
                  </button>
                </div>
              </FormGroup>
              {isPasswordInvalid && (
                  <div style={{ color: 'red'}}>
                      As senhas fornecidas são diferentes ou inválidas. Nenhuma modificação foi realizada.
                  </div>
              )}



            <div class={style.container_separar}>
                <button onClick={Alterar_senha} class={style.Confirm} >Alterar Senha</button>
                <button onClick={toggle_S} class={style.Delete} >Excluir Conta</button>
            </div>
          </>
        )}

      </Form>

      
        <div className="modal-form">
        <Modal isOpen={modal} toggle={toggleModal}>
          <ModalBody>
            <Form  className="modal-form">
              <h1 className="form-title">Opa, falta dados a serem preenchidos em sua conta.</h1>
              <p className="form-description">Finalize o preenchimento dos seus dados para acessar a página de vagas e, não esqueça de adicionar um currículo.</p>
              <Button  color="secondary"  className="login-button" onClick={toggleModal}>Fechar</Button>
            </Form>
          </ModalBody>
        </Modal>
        </div>
      </div>
    </>
  );
};

export default UserConfig;