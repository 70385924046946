import React, { useEffect , useContext} from 'react';

import { useSearchParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import UserContext from '../../UserContext.js';

import axios from 'axios'; // Certifique-se de ter o Axios instalado

function Verificador() {
    const navigate = useNavigate();
    const { setUserInfo } = useContext(UserContext);
    let [searchParams] = useSearchParams();
    let email = searchParams.get("email");
    let v_code = searchParams.get("v_code");

useEffect(() => {
    let dadosVerificadores = { email, v_code };

    axios.post('https://linkestagio.com.br/App.php?operation=verificar-email', dadosVerificadores, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
    .then(response => {
        console.log(response.data)
        
        if (response.data !== null && typeof response.data === 'object' && !Array.isArray(response.data)) {
            if (response.data.success) {
                const userData = response.data.data;
                if (userData !== undefined) {
                    sessionStorage.removeItem('userInfo');
                    sessionStorage.setItem('userInfo', JSON.stringify(userData)); // Armazena userData no localStorage
                    navigate('/configEstudante'); // Redireciona para a página de perfil
                } else {
                    console.error('Dados de usuário indefinidos:', response.data);
                    alert('Dados de usuário não recebidos corretamente.');
                }
            }
        } else {
            console.log(response.data)
            //alert('Resposta do servidor não é um objeto válido.');
        }
    })
    .catch(error => {
        console.error('Erro na requisição:', error);
        alert('Erro na requisição.');
    });
}, [email, v_code]);

    return (
        navigate('/')
    );
}

export default Verificador;
