
import style from './profileInfo.module.css'



const ProfileInfo = ({ userInfo }) => {
    return(
        <div className={style.profileGroup}>
            <p className={`${style.info} ${style.infoName}`}>{userInfo.Nome_Estudante}</p>
            <p className={`${style.info} ${style.infoDesc}`}>{userInfo['instituicao_ensino'].Nome_Instituicao}</p>
        </div>
    )
}

export default ProfileInfo;