import React, { useState,useEffect } from 'react';
import { Button, Modal, Form, Label, FormGroup, Input, ModalHeader, ModalBody } from 'reactstrap';
import axios from 'axios';
import './formStyleRegisterLogin_.css';
import { useNavigate } from 'react-router-dom';
import { FaEye, FaEyeSlash } from 'react-icons/fa'; 
import { Checkmark } from 'react-checkmark'


const LoginForm = ({ isLoginModalOpen, toggleRegisterModal, onLoginSuccess }) => {

  const navigate = useNavigate();

  const [formData, setFormData] = useState({ email: '', senha: '' });
  const [ResetData, setResetData] = useState({ resetEmail: ''});
  const [loginModal, setLoginModal] = useState(false);
  const [resetPasswordModal, setResetPasswordModal] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState('');
  const [resetPasswordNewModal, setResetPasswordNewModal] = useState(false);
  const [newPasswordData, setNewPasswordData] = useState({ password: '', confirmPassword: '' });
  const [ispasswordError, setPasswordError] = useState('');
  const [isContaExiste, setContaExiste] = useState(false);

  const [isPasswordVisible, setPasswordVisible] = useState(false);
  const [isPasswordVisible2, setPasswordVisible2] = useState(false);
  const [isPasswordVisible3, setPasswordVisible3] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(prev => !prev);
  };

  const togglePasswordVisibility2 = () => {
    setPasswordVisible2(prev => !prev);
  };

  const togglePasswordVisibility3 = () => {
    setPasswordVisible3(prev => !prev);
  };

  const [modal_C, setModal_C] = useState(false);
  const toggle_C = () => setModal_C(!modal_C);
  
  const toggleLoginModal = () => setLoginModal(!loginModal);
  const toggleResetPasswordModal = () => setResetPasswordModal(!resetPasswordModal);

  const handleTogglePassword = () => {

    toggleLoginModal(); // Isso irá fechar o modal atual se estiver aberto
    setTimeout(() => {
      toggleResetPasswordModal(); // Isso irá abrir o outro modal se estiver fechado
    }, 350);
  };


  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleChangeReset = (event) => {
    const { name, value } = event.target;
    setResetData({ ...ResetData, [name]: value });
  };

  const handleLogin = () => {
    toggleLoginModal();
    onLoginSuccess(); // Isso abrirá o modal de confirmação no LoginComponent
  };
  
  const handleResetPassword = (event) => {
    event.preventDefault();

    axios.post('https://linkestagio.com.br/App.php?operation=ResetPassword-estudante1', ResetData)
      .then((response) => {
        if (response.data !== '') {
          setFeedbackMessage('E-mail enviado com sucesso!Verifique sua caixa de entrada.');


          axios.post('https://linkestagio.com.br/App.php?operation=ResetPassword-estudante2', ResetData)
          .then((response2) => {
          })
          .catch((error2) => {
            // Tratar erro do segundo axios.post
          });


        } else {
          setFeedbackMessage('Não foi possivel enviar o email, tente novamente em alguns instantes.');
        }
      })
      .catch((error) => {
        setFeedbackMessage('Erro de conexão com o servidor.');
      });
  };
  
  // Ou, crie uma nova função específica para fechar o modal de recuperação de senha
  const closeResetPasswordModal = () => {
    setResetPasswordModal(false); // Isso garantirá que o modal seja fechado
  };

  function validatePassword(password, confirmPassword) {
    const hasMinLength = password.length >= 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumbers = /[0-9]/.test(password);
    const isMatch = password === confirmPassword;
  
    return hasMinLength && hasUpperCase && hasLowerCase && hasNumbers && isMatch;
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    axios.post('https://linkestagio.com.br/App.php?operation=login-estudante', formData)
    
      .then((response) => {
        console.error(response);

        if (response.data !== null && typeof response.data === 'object' && !Array.isArray(response.data)  && response.data.message === 'Login bem-sucedido') {
          const userData = response.data.data;
          sessionStorage.removeItem('userInfo');
          sessionStorage.setItem('userInfo', JSON.stringify(userData)); // Armazena userData no localStorage
          //setUserInfo(userData); // Atualiza o UserContext
          navigate('/area'); // Redireciona para a página de perfil
        } else {
          setContaExiste(true);
          console.error('Erro de login:', response.data);
        }
      })
      .catch((error) => {
        console.error('Erro de login:', error);
      });
  };

  useEffect(() => {

    setTimeout(() => {
      setLoginModal(isLoginModalOpen);
    }, 350);
  }, [isLoginModalOpen]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const email = urlParams.get('email');
    const token = urlParams.get('token');

    if (email && token) {
      // Fazer chamada de API para validar
      axios.post('https://linkestagio.com.br/App.php?operation=CheckPassword-estudante', { email, token })
        .then(response => {

          if (response.data == 'O token é válido e o hash corresponde.') {
            setResetPasswordNewModal(true);
          }
        })
        .catch(error => console.error(error));
    }
  }, []);

  const handleChangeNewPassword = (event) => {
    const { name, value } = event.target;
    setNewPasswordData({ ...newPasswordData, [name]: value });
  };

  // Aqui vai a função para submeter a nova senha
  const handleSubmitNewPassword = (event) => {
    event.preventDefault();
    const urlParams = new URLSearchParams(window.location.search);

    const email = urlParams.get('email');

    if (!validatePassword(newPasswordData.password, newPasswordData.confirmPassword))  {
      setPasswordError('As senhas não coincidem ou não estão no padrão.');
      return; // Interrompe a execução se as senhas não coincidirem
    }

    const dataToSend = { ...newPasswordData, email }; // Adiciona o email ao objeto de dados

    axios.post('https://linkestagio.com.br/App.php?operation=NewPassword-estudante', dataToSend)
    .then(response => {
    })

    setResetPasswordNewModal(!resetPasswordNewModal);
    setTimeout(() => {
      toggle_C(true);
    }, 450);



  };
  

  return (
    <div>
        <div className="button-container">
          <Button className="access-button" style={{backgroundColor: '#7e2553'}} onClick={toggleLoginModal}>
            <span className="access-sub-text">Acessar</span>
          </Button>
        </div>
        <Modal isOpen={loginModal} toggle={toggleLoginModal}>
        <ModalHeader style={{ paddingBottom: 0 , borderBottom: 'none' }} toggle={toggleLoginModal}></ModalHeader>

          <ModalBody>
          <Form onSubmit={handleSubmit} className="modal-form">
                    <h1 className="form-title">Acesse a Página</h1>
                    <p className="form-description">Acesse a Plataforma Link Estágio.</p>

                    <div className="input-block">
                        <Label for="email" className="linkLabel">Email</Label>
                        <Input
                          type="email"
                          name="email"
                          id="email"
                          placeholder="Email"
                          value={formData.email}
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <div className="input-block" style={{ position: 'relative' }}>
                      <Label for="senha" className="linkLabel">Senha</Label>
                        <Input
                          name="senha"
                          id="senha"
                          placeholder="Senha"
                          type={isPasswordVisible ? 'text' : 'password'}
                          value={formData.senha  || ''}
                          onChange={handleChange}
                          required
                        />
                        <button
                        onClick={togglePasswordVisibility}
                        style={{
                          position: 'absolute',
                          right: '15px',
                          top: '70%',
                          transform: 'translateY(-50%)',
                          border: 'none',
                          background: 'transparent',
                          cursor: 'pointer'
                        }}
                        type="button"
                      >
                      {isPasswordVisible ? <FaEyeSlash /> : <FaEye />}
                      </button>
                      </div>
                      <a href="#" className="forgot-password" onClick={handleTogglePassword}>Esqueceu sua Senha?</a>
                      <Button className="login-button" variant="" type="submit">Login</Button>
                      {isContaExiste && (
                        <div style={{ color: 'red'}}>
                            Erro de login ou conta não verificada.
                        </div>
                      )}
                    <p className="sign-up-text">
                    Não tem uma conta? <a href="#" onClick={handleLogin}>Faça seu cadastro!</a>
                    </p>
                </Form>
      </ModalBody>
    </Modal>
    <Modal isOpen={resetPasswordModal} toggle={toggleResetPasswordModal}>
    <ModalHeader style={{ paddingBottom: 0 , borderBottom: 'none' }} toggle={toggleResetPasswordModal}></ModalHeader>

        <ModalBody>
          <Form onSubmit={handleResetPassword}  className="modal-form">
            <h1 className="form-title">Recuperar Senha</h1>
            <p className="form-description">Digite seu email para receber o email de recuperação de senha.</p>
            <FormGroup>
            <div className="input-block">
              <Label for="resetEmail">Email</Label>
              <Input
                type="email"
                name="resetEmail"
                id="resetEmail"
                placeholder="Digite seu email"
                onChange={handleChangeReset}
                required
              />
              </div>
            </FormGroup>
            <Button className="login-button" variant="" type="submit">Enviar email de recuperação</Button>
            {feedbackMessage && <p className="form-description">{feedbackMessage}</p>}
          </Form>
        </ModalBody>
      </Modal>
      <Modal isOpen={resetPasswordNewModal} toggle={() => setResetPasswordNewModal(!resetPasswordNewModal)}>
      <ModalHeader style={{ paddingBottom: 0 , borderBottom: 'none' }} toggle={() => setResetPasswordNewModal(!resetPasswordNewModal)}></ModalHeader>

        <ModalBody>
          <Form onSubmit={handleSubmitNewPassword} className="modal-form">
            <h1 className="form-title">Redefinir Senha</h1>
            <p className="form-description">Digite sua nova senha para acessar a plataforma</p>
            <FormGroup>
            <div className="input-block">
              <Label for="password">Nova Senha</Label>
              <div className="input-block" style={{ position: 'relative' }}>

              <Input name="password" id="password" placeholder="Nova Senha" onChange={handleChangeNewPassword}
               type={isPasswordVisible2 ? 'text' : 'password'} required />
              <button
                        onClick={togglePasswordVisibility2}
                        style={{
                          position: 'absolute',
                          right: '15px',
                          top: '50%',
                          transform: 'translateY(-50%)',
                          border: 'none',
                          background: 'transparent',
                          cursor: 'pointer'
                        }}
                        type="button"
                      >
                    {isPasswordVisible2 ? <FaEyeSlash /> : <FaEye />}
                  </button>
                </div>
            </div>
            </FormGroup>
            <FormGroup>
            <div className="input-block">

              <Label for="confirmPassword">Confirmar Nova Senha</Label>
              <div className="input-block" style={{ position: 'relative' }}>

                <Input name="confirmPassword" id="confirmPassword" placeholder="Confirmar Nova Senha" 
                onChange={handleChangeNewPassword} type={isPasswordVisible3 ? 'text' : 'password'}
                required />
                <button
                          onClick={togglePasswordVisibility3}
                          style={{
                            position: 'absolute',
                            right: '15px',
                            top: '50%',
                            transform: 'translateY(-50%)',
                            border: 'none',
                            background: 'transparent',
                            cursor: 'pointer'
                          }}
                          type="button"
                        >
                      {isPasswordVisible3 ? <FaEyeSlash /> : <FaEye />}
                    </button>
                  </div>
            
            </div>
            </FormGroup>
            {ispasswordError && (
              <div style={{ color: 'red'}}>
                Senhas diferentes ou invalidas.
              </div>
            )}
            <Button className="login-button" variant="" type="submit">Redefinir Senha</Button>
          </Form>
        </ModalBody>
      </Modal>
      <Modal isOpen={modal_C} toggle={toggle_C} className="modal-form">
        <ModalBody>
        <Checkmark size='64px' color='#2C7865' />
        <br></br>
        <h1 className="form-title">Senha Alterada Com Sucesso!</h1>
        <p className="form-description">Você ja pode usar a sua nova senha na plataforma Link Estágio.</p>
        <Button className="login-button" variant="custom" onClick={toggle_C}>Voltar para a página inicial</Button>
        </ModalBody>
      </Modal>
  </div>
  );
}

export default LoginForm;