import React, { useContext, useEffect } from 'react';
import UserContext from '../../UserContext.js';
import {Label, Form, FormGroup, Input} from 'reactstrap';
import { useNavigate } from 'react-router-dom';

import VerticalNavbar from "../../components/verticalHavbar/VeticalNavbar2";

import UserConfig from "../../components/estudante/plataforma/userConfig";

import EstagioLayout from "../../components/estudante/plataforma/estagioLayout";


function Perfil_Estudante() {
    const navigate = useNavigate();



    const userInfo = JSON.parse(sessionStorage.getItem('userInfo'));



    useEffect(() => {
        if (!userInfo) {
            navigate('/');
        }
    }, [userInfo, navigate]);
    if (!userInfo) return <div></div>;

    return (
                
        <div>
            <VerticalNavbar userInfo={userInfo} />
            <EstagioLayout />
        </div>
    )
}

export default Perfil_Estudante;